.hp-content-wrap {
  .hp-digitalkit-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
  }

  .hp-digitalkit-desc {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  .hp-digitalkit-tabs {
    flex-basis: 100%;
    width: 100%;
    border-bottom: 1px solid #efefef;
    margin-top: 2rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 575px) {
      margin-top: 1rem;
    }

    .hp-digitalkit-tab {
      justify-content: center;
      text-transform: uppercase;
      align-items: center;
      padding: 0 1rem;

      cursor: pointer;

      img {
        width: 50px;
        height: 50px;

        @media screen and (max-width: 575px) {
          width: 50px;
          height: 50px;
        }
      }

      div {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        letter-spacing: 0.95px;
        margin-top: 0.5rem;
        justify-content: center;
        color: #bfbfbf;
        align-items: center;
        text-align: center;

        @media screen and (max-width: 575px) {
          font-size: 14px;
        }
      }

      .hp-digitalkit-tab-text {
      }
    }

    .hp-digitalkit-tab-active {
      border-bottom: 5px solid #0096d6;

      .hp-digitalkit-tab-text-active {
        color: #000;
      }
    }
  }

  .hp-digitalkit-tab-content {
    flex-direction: column;
    .hp-digitalkit-filter-wrap {
      width: 250px;
      position: relative;

      @media screen and (max-width: 575px) {
        width: 100%;
      }

      img {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #d3d3d3;
      }
    }

    .hp-digitalkit-video-wrap {
      margin-top: 1rem;
      padding-bottom: 2rem;

      .hp-digitalkit-video-tile {
        margin-top: 1rem;
        flex-direction: column;
        .hp-digitalkit-video-img {
          width: 100%;
          height: 185px;
          background: url("../../assets/images/video_bg.png") center no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 6px;
        }

        .hp-digitalkit-video-title {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 14px;
          margin-top: 1rem;
        }

        .hp-digitalkit-video-desc {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 14px;
          margin-top: 0.7rem;
        }
      }
    }

    .hp-digitalkit-content-wrap {
      margin-top: 1rem;
      padding-bottom: 2rem;
      flex-wrap: wrap;
      .hp-digitalkit-content-tile {
        margin-top: 1rem;
        .hp-digitalkit-content-img {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 57.5px;
          height: 55px;
        }

        .hp-digitalkit-content-img-pdf {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 47.5px;
          height: 55px;
        }

        .hp-digitalkit-content-img-video {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 65.5px;
          height: 55px;
        }

        .hp-digitalkit-content-img-file {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 45.5px;
          height: 55px;
        }

        .img-ppt {
          background: url("../../assets/images/report/presentation-1.svg")
            center no-repeat;
          background-size: cover;
        }

        .img-audio {
          background: url("../../assets/images/report/music-folder-1.svg")
            center no-repeat;
          background-size: cover;
        }

        .img-video {
          background: url("../../assets/images/report/video-player.svg") center
            no-repeat;
          background-size: cover;
        }

        .img-file {
          background: url("../../assets/images/report/document-1.svg") center
            no-repeat;
          background-size: cover;
        }

        .img-link {
          background: url("../../assets/images/report/link.svg") center
            no-repeat;
          background-size: cover;
        }

        .img-pdf {
          background: url("../../assets/images/report/img-pdf.svg") center
            no-repeat;
          background-size: cover;
        }

        .hp-digitalkit-content-title {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 14px;
          margin-top: 1rem;
          height: auto;
        }

        .hp-digitalkit-content-desc {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 14px;
          margin-top: 0.7rem;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 110px;
          height: 110px;
        }

        .hp-digitalkit-content-link {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 14px;
          color: #0096d6;
          text-decoration: underline;
          margin-top: 0.5rem;
          cursor: pointer;
          display: flex;
        }
      }
    }
  }
}

.toolkit-filter {
  appearance: none;
  color: #000;
  border: 1px solid #000;
  width: 100%;
  height: 38px;
  padding: 0 0.5rem;
  border-color: #d3d3d3;
  border-radius: 5px;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.modal-iframe-content {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.toolkit-filter-msg {
  display: flex;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 0.5rem;
  flex-direction: column;

  p {
  }
}

.toolkit-video-link {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #0096d6;

  &:hover {
    color: #0096d6;
  }
}

.modal {
  .modal-dialog {
    max-width: max-content !important;
  }
}

.bp-banner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0096d6;
  color: #fff;
  box-shadow: 0px 10px 36px #00577c5d;
  border-radius: 20px;
  padding: 3rem 6rem;
  margin-top: 1rem;

  @media screen and (max-width: 575px) {
    padding: 3rem 2rem;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0px !important;
  }

  .hp-bp-banner-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 40px;
    @media screen and (max-width: 575px) {
      font-size: 32px;
    }
  }

  .hp-bp-banner-desc {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    @media screen and (max-width: 575px) {
      margin-top: 0.5rem;
    }
  }

  .hp-bp-banner-time {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin-top: 0.5rem;
    @media screen and (max-width: 575px) {
    }
  }

  .hp-bp-banner-start {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    cursor: pointer;
    @media screen and (max-width: 575px) {
    }
  }
}
