@import "../../../scss/colors";

.planner-customise {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  height: 75vh;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .timer-container {
      width: 170px;
      height: 45px;
      padding: 8px 8px;
      border: 0.5px solid #d8d8d8;
      border-radius: 11px;
      position: fixed;
      right: 15%;
      background-color: white;

      @media screen and (max-width: 768px) {
        right: 5%;
      }

      @media screen and (max-width: 425px) {
        right: 5%;
        top: 1rem;
        width: 120px;
        height: 36px;
        padding: 5px 2px;
        border-radius: 6px;
      }

      .stopwatch {
        height: 28px;
        width: 24px;
        margin-left: 8px;

        @media screen and (max-width: 425px) {
          height: 20px;
          width: 16px;
        }
      }

      .time-left {
        color: #0096d6;
        font-size: 15px;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        margin-left: 11px;
        margin-top: 4px;

        @media screen and (max-width: 425px) {
          margin-left: 7px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .content {
      margin-top: 100px;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      .info-card {
        width: 700px;
        padding: 0 63px 0 63px;

        .icon {
          padding: 10px;
          border-radius: 50%;
          margin-bottom: 20px;

          path {
            fill: #ffffff;
          }
        }

        .info {
          font-size: 32px;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        }

        .redirection {
          text-decoration: none;
          color: $primary-color;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 32px;
          display: flex;
          align-items: center;

          .right-arrow {
            background-color: $primary-color;
            padding: 10px;
            border-radius: 50%;
            margin-left: 10px;
            color: #ffffff;
            font-size: 10px;

            path {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }
}
