.edc-container {
  .edc-bg-wrap {
    height: 193.5px;
    width: 100%;
    object-fit: cover;
  }

  .edc-filter-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;

    .edc-filter-contain {
      width: 60%;
      @media screen and (max-width: 576px) {
        width: 80%;
      }
      .title {
        font-size: 18px;
        margin-bottom: 0;
      }

      .heading {
        font-size: 32px;
        white-space: nowrap;
        margin: 0;
        @media screen and (max-width: 576px) {
          white-space: normal;
        }
      }

      .input-wrapper {
        border: 1px solid #e3e3e3;
        width: 100%;
        padding: 5px 17px;
        position: relative;
        margin: 17px 0;
        height: 40px;
        .input-label {
          position: absolute;
          top: -11px;
          padding: 0px 5px;
          left: 15px;
          background-color: #fff;
          font-size: 14px;
        }
        .input-text {
          color: #bfbfbf;
          font-size: 14px;
          padding-top: 5px;
          margin-bottom: 0;
        }
      }
    }
    .back-btn {
      color: #0096d6;
      font-size: 18px;
      text-decoration: underline;
      text-underline-offset: 2px;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}
