.connected-wrap {
  padding: 60px 10px;
  border-bottom: 1px solid #f2f2f2;

  @media only screen and (max-width: 600px) {
    padding: 40px 5px;
  }

  .connected-text {
    font-size: 60px;
    line-height: 65px;
    @media only screen and (max-width: 600px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .checkout-text {
    margin-bottom: 0;
    font-size: 60px;
    color: #0096d6;
    font-size: 32px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.edc-get-connect {
  background: #0096d6 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 36px #00000029;
  border-radius: 21px;
  padding: 3rem 4rem;
  color: #fff;

  @media screen and (max-width: 575px) {
    padding: 3rem 2rem;
  }

  .edc-get-connect-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 60px;
    line-height: 60px;

    @media screen and (max-width: 575px) {
      font-size: 40px;
      line-height: 45px;
    }
  }

  .edc-get-connect-sub-title {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 2rem;

    @media screen and (max-width: 575px) {
      font-size: 24px;
    }
  }

  .edc-get-connect-desc {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 1rem;
    line-height: 37px;

    @media screen and (max-width: 575px) {
    }
  }

  .edc-get-connect-why {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 2rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 575px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }

    .edc-get-connect-why-item {
      color: #fff;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 20px;
      line-height: 26px;

      @media screen and (max-width: 575px) {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
      }

      .edc-get-connect-why-item-title {
        margin-top: 1rem;
        @media screen and (max-width: 575px) {
          margin-left: 1rem;
        }
      }
    }
  }

  .extra-margin {
    margin-top: 3rem;
  }

  .connect-me-btn {
    margin-top: 1rem;
    background-color: #fff;
    color: #0096d6;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 14px;
    border: none;
    border-radius: 20px;
    padding: 0.3rem 1.5rem;
  }
}
