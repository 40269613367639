.hp-assessment-submit {
}

.hp-assessment {
  padding: 2rem;

  @media screen and (max-width: 576px) {
    padding: 1rem;
  }
}

.hp-assessment-completed-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.hp-final-submit-wrap {
  padding: 0 2rem;

  @media screen and (max-width: 576px) {
    padding: 0 1rem;
  }
}

.hp-guide-assessment-block {
  border-bottom: 1px solid #e3e3e3;
}

.hp-submit-btn {
  background-color: #000;
  color: #fff;
  height: 44px;
  padding: 0 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.hp-seection-completed {
  flex-direction: column;
  min-height: 700px;
}

.hp-section-complete-btn {
  background-color: #000;
  color: #fff;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hp-seection-completed-msg {
  flex-direction: column;
  font-size: 22px;
  text-align: center;

  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
}

.hp-completed-card-wrap {
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  @media screen and (min-width: 576px) {
    margin: 2rem 0;
  }
  .hp-completed-card {
    box-shadow: 0px 0px 40px #00000029;
    border-radius: 11px;
    background-color: #fff;
    @media screen and (min-width: 576px) {
      flex: 0 0 47%;
      max-width: 47%;
    }

    @media screen and (max-width: 568px) {
      margin-top: 1rem;
    }

    .hp-completed-card-title {
      line-height: 21px;
    }

    .hp-assessment-view-report {
      color: #9d9d9d;
      display: flex;
      justify-content: flex-end;
    }

    .hp-assessment-final-submit {
      color: #9d9d9d;
      line-height: 11px;
    }
  }
}

.hp-submit-v2-text {
  padding: 0 15%;

  @media screen and (max-width: 575px) {
    margin-top: 1rem;
  }
}

.text-red {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #ff0000;
}

.hp-assessment-link {
  color: #0096d6;

  :active {
    color: #0096d6;
  }
}

.hp-section-end-img {
  width: 205px;
  height: 197px;
  margin-bottom: 1rem;

  @media screen and (max-width: 576px) {
    width: 115px;
    height: 110px;
  }
}

.warmup-retake-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0096d6;
}

.warmup-retake-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #9d9d9d;
}

.warmup-retake {
  margin-bottom: 0.5rem;
}

.assessment-retake-link {
  color: #0096d6;
  text-decoration: underline;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.hp-completed-retake {
  a {
    color: #0096d6;

    &:hover {
      color: #0096d6;
    }
  }
}

.hp-seection-completed-user-msg {
  text-align: center;
}

.start-section-btn-title {
  text-align: center;
  line-height: 18px;
  @media screen and (max-width: 575px) {
    font-size: 16px !important;
  }
}

.hp-asmnt-submit-btn {
  @media screen and (max-width: 575px) {
    text-align: center !important;
    line-height: 18px !important;
    font-size: 18px !important;
  }
}

.daashboard-download-responses-wrap {
  max-height: 70px;
  padding: 0 !important;
  padding-top: 0.5rem !important;
  .daashboard-download-responses-btn {
    cursor: pointer;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    color: #0096d6;
    height: 50px;
  }
}

.ux-qualifier-updates {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 32px;
  margin-bottom: 1rem;
  width: 550px;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 24px;
    width: 335px;
  }
  span {
    text-align: center;
  }

  .ux-bold-msg {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 32px;

    @media screen and (max-width: 575px) {
      font-size: 24px;
    }
  }
}

.ux-msg-completion-screen-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .ux-msg-completion-screen-icon-lock {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0096d6;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    margin-right: 1rem;
  }
  .ux-msg-completion-screen-icon {
    background-color: #525252;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    margin-right: 1rem;
  }
}

.custom-modal-animation {
  .modal-content {
    border-radius: 2rem;
  }

  .completion-modal-msg {
    padding: 2rem;
    .completion-modal-msg-text-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .completion-modal-msg-text-1 {
        text-align: center;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        @media screen and (max-width: 575px) {
          font-size: 14px;
        }
      }

      .completion-modal-msg-text-2 {
        text-align: center;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 32px;
        width: 450px;

        @media screen and (max-width: 575px) {
          width: 300px;
          font-size: 22px;
        }
      }

      .completion-modal-msg-text-3 {
        text-align: center;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 22px;
        width: 350px;
        @media screen and (max-width: 575px) {
          width: 250px;
          font-size: 18px;
        }
      }

      .regular-class {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 32px;
        @media screen and (max-width: 575px) {
          font-size: 22px;
        }
      }
    }

    .completion-modal-msg-btn-wrap {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .completion-modal-msg-btn {
      }
    }
  }
}

.hp-lets-begin-assessment {
  flex-direction: column;
  font-size: 22px;
  text-align: center;

  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
}
