.hp-dashboard {
  .hp-dashboard-cta-wrap {
    margin-top: 1.5rem;

    div {
      width: 144px;
      padding: 1rem 4rem;
    }
  }

  .hp-retake-link {
    cursor: pointer;
    a {
      color: #0096d6;
    }
  }

  .hp-assessment-link {
    a {
      color: #0096d6;

      &:hover {
        color: #0096d6;
      }
    }
  }

  .hp-guide-assessment-block {
    .warmup-retake-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0096d6;
    }

    .warmup-retake-text {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      color: #9d9d9d;
    }

    .warmup-retake {
      margin-bottom: 0.5rem;
    }

    .assessment-retake-link {
      color: #0096d6;
      text-decoration: underline;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
    }
  }

  .hp-take-assessment-text {
    justify-content: center;
    align-items: center;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
}

.hp-data-wipe-confirm {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #0096d6;
  text-decoration: underline;
  margin-right: 0.5rem;
  cursor: pointer;
}

.hp-data-wipe-cancel-cta {
  height: 44px !important;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px !important;
}

.hp-reatake-alert-wrap {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;

  @media screen and (max-width: 575px) {
    padding: 1rem 1rem;
  }

  .hp-retake-warn-text-1 {
    .retake-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      color: #ed2f2f;
    }

    .retake-body {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      color: #ed2f2f;
      margin-top: 1rem;
    }

    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .hp-retake-warn-text-2 {
    margin-bottom: 1rem;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;

    .retake-confirm-text {
      margin-top: 1rem;
    }
  }
}

.hp-report {
  .hp-report-username {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 22px;
  }

  .hp-report-status {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }

    @media screen and (max-width: 576px) {
      border-top: 1px solid #e3e3e3;
      padding-top: 1rem;
      margin-top: 1rem;
    }
  }

  .hp-result-landing-chart-wrap {
    flex-basis: 30%;
  }

  .hp-chart-text {
    color: #626262;
    margin-top: 2rem;
  }

  .hp-report-status__graph {
    display: flex;

    justify-content: center;

    @media screen and (min-width: 576px) {
      margin-top: 2rem;
    }
  }

  .hp-report-status-score {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 576px) {
      flex-basis: 70%;
      margin-left: 3rem;
    }

    .hp-assessment-completed {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 22px;
    }

    .hp-assessment-scores-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    .hp-assessment-scores {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
      padding-top: 0.8rem;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 1rem;

      @media screen and (max-width: 576px) {
        border-top: 1px solid #e3e3e3;
        margin-top: 1rem;
        padding-top: 1rem;
      }
    }
  }

  .hp-assessment-scores-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .hp-assessment-scores-section-text {
      display: flex;
      width: 100%;
      text-transform: uppercase;
    }
  }

  .hp-assessment-scores-section-bar-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0.5rem;
  }

  .hp-assessment-scores-section-bar {
    display: flex;
    width: 100%;
    height: 7px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    position: relative;

    .hp-assessment-scores-section-bar-fill {
      display: flex;
      position: absolute;
      height: 7px;
      width: 70%;
      background: transparent linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%)
        0% 0% no-repeat padding-box;
    }
  }

  .hp-report-score {
    display: flex;
    flex-direction: row;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 12px;

    .hp-report-score-left {
      display: flex;
      flex-basis: 50%;
      color: #707070;
    }

    .hp-report-score-right {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
    }
  }

  .hp-assessment-last-updated {
    color: #bfbfbf;
  }

  .donutchart-indicator {
  }
}
.hp-report-landing-mob {
  .donutchart-text-val {
    font-size: 28px;
  }

  .donutchart-text-label {
    font-size: 10px;
  }

  .donutchart-text-percent {
    font-size: 12px;
  }
}

.hp-100-report-desktop {
  @media screen and (max-width: 575px) {
    display: none !important;
  }
}

.hp-100-report-mobile {
  @media screen and (min-width: 575px) {
    display: none !important;
  }
}

.hp-other-lan-msg-wrap {
  align-items: center;

  .other-lan-msg {
    width: 500px;
    text-align: center;
    margin-top: 3rem;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;

    @media screen and (max-width: 575px) {
      font-size: 14px;
      width: 300px;
    }
  }
}

.text-red {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #ff0000;
}

.hp-asmnt-submit-btn {
  @media screen and (max-width: 575px) {
    text-align: center !important;
    line-height: 18px !important;
    font-size: 18px !important;
  }
}

.daashboard-download-responses-wrap {
  max-height: 70px;
  padding: 0 !important;
  padding-top: 0.5rem !important;
  .daashboard-download-responses-btn {
    cursor: pointer;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    color: #0096d6;
    height: 50px;
  }
}

.dashboard-retake-assessment {
  cursor: pointer;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  color: #0096d6;
}

.tab {
  margin-top: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  width: 100%;
  padding: 10px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  p {
    color: #0096d6;
    text-align: center;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.tab-active {
  cursor: pointer;
  width: 100%;
  background-color: #0096d6;
  p {
    color: #fff;
    text-align: center;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    margin-bottom: 0;
    font-size: 18px;
  }
}
