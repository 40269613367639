@import "../../../scss/colors";

.planner-timeline {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

  .technology-selection-wrap {
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .timer-container {
      width: 170px;
      height: 45px;
      padding: 8px 8px;
      border: 0.5px solid #d8d8d8;
      border-radius: 11px;
      position: absolute;
      right: 15%;
      background-color: white;

      @media screen and (max-width: 768px) {
        right: 5%;
      }

      @media screen and (max-width: 425px) {
        right: 5%;
        top: 1rem;
        width: 120px;
        height: 36px;
        padding: 5px 2px;
        border-radius: 6px;
      }

      .stopwatch {
        height: 28px;
        width: 24px;
        margin-left: 8px;

        @media screen and (max-width: 425px) {
          height: 20px;
          width: 16px;
        }
      }

      .time-left {
        color: #0096d6;
        font-size: 15px;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        margin-left: 11px;
        margin-top: 4px;

        @media screen and (max-width: 425px) {
          margin-left: 7px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .timeline {
      width: 768px;
      margin-top: 4rem;

      @media screen and (max-width: 575px) {
        width: 100%;
        margin-top: 2rem;
      }

      .heading {
        display: flex;
        align-items: center;

        .head-count {
          background-color: #0096d6;
          color: white;
          border-radius: 50%;
          text-align: center;
          padding: 0px 7px;
          display: flex;
          width: 31px;
          height: 31px;
          justify-content: center;
          align-items: center;
        }

        .head-text {
          margin-left: 20px;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
          color: $primary-color;
          text-transform: uppercase;
        }
      }

      .content.final-step {
        border-left: 1px solid #ffffff;
      }
      .content {
        margin-left: 16px;
        border-left: 2px solid #0096d640;

        .content-body {
          margin: 20px;

          .content-body-info {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 32px;

            @media screen and (max-width: 575px) {
              font-size: 22px;
            }
          }

          .technology-selection-desc {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 18px;
          }

          .table-element {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .ele {
              width: 100%;
              text-align: left;
              padding-right: 1rem;

              .h {
                text-transform: uppercase;
                font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                font-size: 18px;
              }

              .v {
                font-size: 22px;
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

                @media screen and (max-width: 575px) {
                  font-size: 18px;
                }
              }
            }
          }

          .select-group-title {
            font-size: 18px;
            font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
            text-transform: uppercase;
          }

          .custom-checkbox {
            margin-bottom: 20px;

            .custom-control-label {
              color: #000;
              font-size: 22px;
              font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            }
          }
        }
        .tc-desc {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 18px;
        }
      }
    }
    .btn-proceed {
      display: flex;
      justify-content: center;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      padding-bottom: 2rem;
      .tech-selection-cta {
        border-radius: 30px;
        border-color: $primary-color;
        background-color: $primary-color !important;
        font-size: 22px;
        text-transform: none;
        cursor: pointer;
        color: #fff;
        padding: 1rem 2rem;
      }
    }
  }
}
