@keyframes img1Animation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes moveImg3 {
    0% {
        top: 0px;
        width: 131px;
        height: 131px;
    }
    50% {
        top: 50px;
        width: 56px;
        height: 56px;
        opacity: 1;
        left: 0px;
    }

    100% {
        left: -100px;
        top:50px;
        opacity: 0;
        width: 56px;
        height: 56px;
    }
}

.hp-completion-animation {
    height: 250px;
    //width: 768px;
    padding:2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .img-containers {
        position: relative;
        height: 250px;
        //width: 768px;
        padding:2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .animatin-img-1 {
        width: 131px;
        height: 131px;
    }
    .animatin-img-2 {
        width: 131px;
        height: 131px;
    }
    .animatin-img-3 {
        width: 131px;
        height: 131px;
        position: relative;
        top: 0px;
        animation-name: moveImg3;
        animation-delay: 1s;
        animation-duration: 1s;
    }

    .ux-msg-completion-screen-images {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    
        .ux-msg-completion-screen-icon-lock{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0096D6;
            width: 56px;
            height: 56px;
            border-radius: 30px;
            margin-right: 1rem;
    
            
        }   
        .ux-msg-completion-screen-icon {
            background-color: #525252;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 56px;
            border-radius: 30px;
            margin-right: 1rem;
            
        }
    }
}