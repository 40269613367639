.assessment-end-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .assessment-end-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .welcome-text {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 24px;
      text-align: center;
      width: 70%;

      @media screen and (max-width: 575px) {
        font-size: 22px;
        width: 100%;
      }
    }

    .welcome-msg {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 24px;
      margin-top: 1rem;
      text-align: center;
      width: 70%;

      @media screen and (max-width: 575px) {
        font-size: 22px;
        width: 100%;
      }
    }

    .assessment-end-btn-wrap {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 21px;

      @media screen and (max-width: 575px) {
        font-size: 21px;
      }
    }

    .assessment-end-link-wrap {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 22px;
      margin-top: 1rem;

      @media screen and (max-width: 575px) {
        font-size: 22px;
      }

      a {
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-decoration: underline;
        margin-top: 1rem;
        cursor: pointer;
      }
    }
  }
}

.ux-qualifier-updates {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 32px;
  margin-bottom: 1rem;
  width: 550px;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 24px;
    width: 335px;
  }
  span {
    text-align: center;
  }

  .ux-bold-msg {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 32px;

    @media screen and (max-width: 575px) {
      font-size: 24px;
    }
  }
}

.ux-msg-completion-screen-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .ux-msg-completion-screen-icon-lock {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0096d6;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    margin-right: 1rem;
  }
  .ux-msg-completion-screen-icon {
    background-color: #525252;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    margin-right: 1rem;
  }
}

.ux-bold-msg-common {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 24px;

  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
}
