.hp-pagespeed {
    .hp-report-graph-wrap {
      width: 173px;
      height: 173px;
      border-radius: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none !important;

      .hp-assessment-complete-graph {
          width: 150px;
          height: 150px;
          background-color: #fff;
          border-radius: 6rem;
          border: 16px solid #0096D6;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .hp-assessment-complete-graph-score {
              font-family: 'HP Simplified Bold', Arial, Helvetica, sans-serif;
              font-size: 32px;
              color: #0096D6;
          }

          .hp-assessment-complete-graph-text {
              font-family: 'HP Simplified Regular', Arial, Helvetica, sans-serif;
              font-size: 14px;
              color: #0096D6;
          }
      }
  }

  .hp-report-graph-wrap-yellow {
    background-color: #ffa60054;
  }
  .hp-report-graph-wrap-red {
    background-color: #ff4f4252;
  }
  .hp-report-graph-wrap-green {
    background-color: #0ace6946;
  }

  .donutchart-track{
      fill: transparent;
      stroke: none !important;
      stroke-width: 26;
    }
    .donutchart-indicator {
      fill: transparent;
      stroke-width: 26;
      stroke-dasharray: 0 10000;
      transition: stroke-dasharray .3s ease;
    }

    .donutchart-indicator-yellow {
      stroke: #ffa400;
    }

    .donutchart-indicator-red {
      stroke: #ff4f42;
    }

    .donutchart-indicator-green {
      stroke: #0ace69;
    }
    
    .donutchart {
      margin: 0 auto;
      border-radius: 50%;
      display: block;
    }
    
    
    .donutchart-text{
      margin: 0 auto;
    }

    .donutchart-text-yellow {
      fill: #ffa400;
    }

    .donutchart-text-red {
      fill: #ff4f42;
    }

    .donutchart-text-green {
      fill: #0ace69;
    }


    .donutchart-text-val{
      font-family: 'HP Simplified Regular', Arial, Helvetica, sans-serif;
      font-size:56px;
    }
    .donutchart-text-percent{
      font-family: 'HP Simplified Regular', Arial, Helvetica, sans-serif;
      font-size:18px;
    }
    .donutchart-text-label{
      font-family: 'HP Simplified Regular', Arial, Helvetica, sans-serif;
      font-size:18px;
    }

  @media screen and (max-width: 576px) {
      .hp-report-landing {
          display: flex;
          
          .hp-report-graph-wrap {
              width: 100px;
              height: 100px;
              .hp-assessment-complete-graph {
                  width: 85px;
                  height: 85px;
                  border: 8px solid #0096D6;
      
                  .hp-assessment-complete-graph-score {
                      font-family: 'HP Simplified Regular', Arial, Helvetica, sans-serif;
                      font-size: 34px;
                      color: #0096D6;
                      letter-spacing: -1.8px;
                      display: flex;
                      width: 100%;
                      justify-content: center;
                      line-height: 30px;
                  }
          
                  .hp-assessment-complete-graph-text {
                      font-family: 'HP Simplified Regular', Arial, Helvetica, sans-serif;
                      font-size: 9px;
                      color: #0096D6;
                      display: flex;
                      width: 100%;
                      justify-content: center;
                      line-height: 10px;
                  }
              }
          }

          .hp-report-status__graph {
              display: flex;
              flex-basis: 40%;
          }

          .hp-report-chart-right-mob {
              display: flex;
              flex-basis: 60%;
              flex-direction: column;
          }
      }
  }
}

