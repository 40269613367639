.hp-toolkit-section {
  margin-top: 2rem;

  .hp-toolkit-content-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 150px;
    height: 150px;
  }

  .hp-toolkit-video-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 150px;
    height: 150px;
  }

  .hp-toolkit-content-title {
    height: 50px;
  }

  .hp-toolkit-content-wrap {
    a {
      .hp-toolkit-content-desc {
        color: #000;
      }

      .hp-toolkit-content-title {
        color: #000;
      }

      .hp-toolkit-content-link {
        color: #0096d6;
      }
    }
  }
}

.hp-toolkit-more-link {
  display: flex;
}

.hp-toolkit-more-link-dektop {
  display: none;
  @media screen and (min-width: 576px) {
    display: flex !important;
  }
}

.doc-modal-body {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.img-pdf {
  background: url("../../assets/images/report/img-pdf.svg") center no-repeat;
  background-size: cover;
}

.hp-toolkit-content-img-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 47.5px;
  height: 55px;
}

.hp-toolkit-content-img-file {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 45.5px;
  height: 55px;
}

.img-ppt {
  background: url("../../assets/images/report/presentation-1.svg") center
    no-repeat;
  background-size: cover;
}

.img-audio {
  background: url("../../assets/images/report/music-folder-1.svg") center
    no-repeat;
  background-size: cover;
}

.img-file {
  background: url("../../assets/images/report/document-1.svg") center no-repeat;
  background-size: cover;
}

.img-link {
  background: url("../../assets/images/report/link.svg") center no-repeat;
  background-size: cover;
}

.toolkit-video-link {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #0096d6;

  &:hover {
    color: #0096d6;
  }
}

.modal {
  .modal-dialog {
    max-width: max-content !important;
  }
}
