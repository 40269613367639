.hp-progress-bar {
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  width: 768px;
  margin-top: 1rem;

  .hp-progress-start {
    display: flex;
    flex: 3%;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 14px;

    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
  }

  .hp-progress-bar-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 92%;
    .hp-progress-bar-gray {
      width: 670px;
      height: 9px;
      background-color: #dbdbdb;
      border-radius: 5px;
      position: absolute;

      @media screen and (max-width: 575px) {
        width: 290px;
      }

      .hp-progress-bar-blue {
        position: absolute;
        height: 9px;
        background-color: #0096d6;
        border-radius: 5px;
        display: inline-flex;
      }

      .hp-progress-milestones {
        position: absolute;
        top: -30px;
        display: inline-flex;
        width: 100%;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;

        @media screen and (max-width: 575px) {
          font-size: 12px;
          top: -27px;
        }
      }

      .hp-progress-points {
        position: absolute;
        display: flex;
        flex-direction: column;
        img.active {
          width: 32px;
          height: 32px;
        }

        img.disabled {
          width: 18px;
          height: 27px;
        }
      }

      .hp-progress-0 {
        left: 5%;
      }

      .hp-progress-1 {
        @media screen and (max-width: 575px) {
          left: 30%;
        }
        left: 50%;
      }

      .hp-progress-2 {
        left: 68%;
        @media screen and (max-width: 575px) {
          left: 55%;
        }
      }

      .hp-progress-3 {
        left: 83%;
        @media screen and (max-width: 575px) {
          left: 75%;
        }
      }

      .hp-progress-4 {
        left: 98%;
        @media screen and (max-width: 575px) {
          left: 94%;
        }
      }
    }
  }

  .hp-progress-filled-0 {
    width: 5%;
  }

  .hp-progress-filled-1 {
    @media screen and (max-width: 575px) {
      width: 30%;
    }
    width: 50%;
  }

  .hp-progress-filled-2 {
    width: 70%;
    @media screen and (max-width: 575px) {
      width: 60%;
    }
  }

  .hp-progress-filled-3 {
    width: 85%;
    @media screen and (max-width: 575px) {
      width: 80%;
    }
  }

  .hp-progress-filled-4 {
    width: 100%;
  }
}
