.assessment-intro-wrap {
  display: flex;
  min-height: 850px;
  @media screen and (max-width: 575px) {
    min-height: 768px;
  }
  justify-content: center;
  align-items: center;
  .assessment-intro-unit {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    .assessment-intro-img {
      margin-bottom: 0.5rem;
      @media screen and (max-width: 575px) {
        width: 60px;
        height: 75px;
      }
    }

    .assessment-intro-heading {
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;

      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }

    .assessment-intro-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 32px;
      color: #0096d6;

      @media screen and (max-width: 575px) {
        font-size: 22px;
      }
    }

    .assessment-intro-desc {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;

      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
    }

    .assessment-intro-btn {
    }
  }
}
