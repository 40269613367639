.hp-report-recommendation {
  margin-top: 1.5rem;
  .hp-report-recommendation-title {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: #000;

    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }

  .hp-report-recommendations-wrap {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #efefef;
  }

  .hp-report-recommendation-item {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;

    .hp-recommend-item-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      @media screen and (max-width: 575px) {
        font-size: 14px;
        margin-top: 0.5rem;
      }
    }

    .hp-recommend-item-score {
      .hp-assessment-scores-section-bar-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 0.5rem;
      }

      .hp-assessment-scores-section-bar {
        display: flex;
        width: 100%;
        height: 7px;
        background: #f4f4f4 0% 0% no-repeat padding-box;
        position: relative;

        .hp-assessment-scores-section-bar-fill {
          display: flex;
          position: absolute;
          height: 7px;
          width: 70%;
          background: transparent
            linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%) 0% 0% no-repeat
            padding-box;
        }
      }

      .hp-report-score {
        display: flex;
        flex-direction: row;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 12px;

        .hp-report-score-left {
          display: flex;
          flex-basis: 50%;
          color: #707070;
        }

        .hp-report-score-right {
          display: flex;
          flex-basis: 50%;
          justify-content: flex-end;
          font-size: 18px;
        }
      }

      @media screen and (max-width: 575px) {
        font-size: 18px;
      }
    }

    .hp-recommend-item-content {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      margin-top: 1rem;
      @media screen and (max-width: 575px) {
        font-size: 18px;
        margin-top: 0.5rem;
      }
    }

    .hp-recommend-item-link {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      margin-top: 1.5rem;
      color: #0096d6;
      font-size: 24px;
      display: flex;
      flex-direction: row;

      .hp-recommend-item-link-icon {
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #0096d6;
        margin-left: 0.5rem;
        text-decoration: none !important;
      }

      .hp-recommend-item-link-text {
        display: flex;
        text-decoration: underline;
        cursor: pointer;

        a {
          color: #0096d6;

          &:hover {
            color: #0096d6;
          }
        }
      }

      @media screen and (max-width: 575px) {
        font-size: 18px;
        margin-top: 1rem;
      }
    }
  }
}
