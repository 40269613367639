.hp-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d9e4e8;

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  .hp-foter-left {
    display: flex;
    flex-basis: 50%;

    @media screen and (max-width: 575px) {
      flex-basis: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      a {
        height: 25px;
      }
    }
  }

  .hp-foter-right {
    display: flex;
    flex-basis: 50%;

    @media screen and (min-width: 576px) {
      justify-content: flex-end;
    }

    @media screen and (max-width: 575px) {
      flex-basis: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      a {
        height: 25px;
      }
    }
  }

  .hp-footer-link {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #818181;
    margin: 1rem;
  }

  .scroll-to-top {
    position: fixed;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4496d6;
    z-index: 99999;
    border: none;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      left: 40%;
    }
    left: 27%;
  }
}
