.assessment-retake-container {
  display: flex;

  .retake-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 8rem;

    @media screen and (max-width: 575px) {
      margin: 0 1rem;
    }

    .retake-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 32px;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 2rem;

      @media screen and (max-width: 575px) {
        font-size: 22px;
      }
    }

    .retake-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .retake-item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #e3e3e3;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      padding: 1.5rem 0;
      .retake-item-title {
        display: flex;
        flex-direction: row;
        .retake-item-title-icon {
          display: flex;
          img {
            width: 32px;
            height: 30px;

            @media screen and (max-width: 575px) {
              width: 20px;
              height: 20px;
            }
          }
        }

        .retake-item-title-text {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #0096d6;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
          line-height: 40px;
          margin-left: 0.3rem;

          @media screen and (max-width: 575px) {
            font-size: 16px;
            line-height: 27px;
          }
        }
      }

      .retake-item-body {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        .retake-item-body-text {
          display: flex;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 18px;

          @media screen and (max-width: 575px) {
            font-size: 14px;
          }
        }

        .retake-item-body-icon {
          display: flex;
          color: #0096d6;
          justify-content: center;
          align-items: center;
          margin-left: 1rem;
        }
      }
    }
  }
}

.retake-assessment-title {
  color: black;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
}
