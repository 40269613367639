.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  @media (min-width: 500px) {
      margin: 0 17rem;
  }
  @media (max-width: 499px) {
      margin: 0;
  }
}

.c {
  padding: 0 15%;
  p {
    font-size: 22px;
  }
  span {
    font-size: 12px;
  }
}

.flex-columns {
  display: flex;
  flex-direction: column;
}

.flex-columns-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-rows {
  display: flex;
  flex-direction: row;
}

.flex-rows-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-static {
  flex: 0 0 auto;
}

.flex-dynamic {
  flex: 1;
}
