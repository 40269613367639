.hp-home-page-banner {
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #0096d6 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #003e586e;
  border-radius: 20px;
  width: 542px;
  padding: 3rem;

  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 2rem;
  }

  .hp-home-banner-title {
    display: flex;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 575px) {
      font-size: 24px;
    }
  }

  .hp-home-banner-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    align-items: flex-end;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      flex-wrap: nowrap;
      align-items: center;
    }

    .hp-home-banner-icon-wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      width: 100px;
      text-align: center;
      @media screen and (max-width: 575px) {
        width: 75px;
        margin-top: 1.5rem;
      }
    }

    .hp-home-banner-icon {
      @media screen and (max-width: 575px) {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }

    .hp-home-banner-icon-text {
      margin-top: 0.5rem;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;

      @media screen and (max-width: 575px) {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 12px;
      }
    }
  }

  .hp-home-banner-access-title {
    display: flex;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 1.5rem;

    @media screen and (max-width: 575px) {
      font-size: 24px;
      margin-top: 1rem;
    }
  }

  .hp-home-banner-access-list {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin-top: 1rem;

    @media screen and (max-width: 575px) {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-around;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 14px;
      margin-top: 0.5rem;
    }

    .hp-home-banner-access-list-col {
      padding-top: 1rem;
      display: inline-flex;
      align-items: center;
      width: 265px;
      .hp-home-banner-access-list-num {
        width: 22px;
        height: 22px;
        background: #0096d6 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #ffffff;
        border-radius: 1rem;
        padding: 0.7rem 0.7rem;
        margin-right: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;
      }
    }
  }
}

.banner-modal-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;

  .hp-white-btn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 22px;
    padding: 1rem;
    color: #0096d6;
    width: 100px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
    cursor: pointer;

    @media screen and (max-width: 575px) {
      font-size: 14px;
      width: 80px;
      height: 38px;
    }
  }
}
