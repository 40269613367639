.edmodal-container {
  .body {
      padding:0 50px;
    .heading {
      color: #0096d6;
      font-size: 22px;
    }
    margin-bottom: 50px;
  }
}
