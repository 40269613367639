@import "../../../scss/colors";

.bp-progress-reach {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

  .bp-progress-reach-define-budget {
    width: 768px;

    .bp-progress-reach-define-budget-lets-start {
      margin-bottom: 42px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      img {
        margin-right: 10px;

        @media screen and (max-width: 700px) {
          width: 40px;
        }
      }

      span {
        font-size: 32px;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

        @media screen and (max-width: 700px) {
          font-size: 22px;
        }

        span {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        }
      }
    }

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    .bp-progress-reach-define-budget-content {
      padding: 67px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 26px #00000014;
      border-radius: 21px;
      opacity: 1;
      width: 100%;

      @media screen and (max-width: 700px) {
        width: 100%;
        padding: 56px 24px;
      }

      .bp-progress-reach-define-budget-content-title {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;

        @media screen and (max-width: 700px) {
          font-size: 16px;
        }
      }

      .bp-progress-reach-define-budget-content-info {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-top: 26px;
      }

      .bp-progress-reach-define-budget-next-btn {
        width: 118px;
        height: 44px;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        background-color: #0096de !important;
        text-transform: capitalize;
        margin-top: 2rem;

        @media screen and (max-width: 700px) {
          margin: auto;
        }
      }
    }
  }

  .bp-progress-reach-website-perform,
  .bp-progress-reach-monthlyy-sales,
  .bp-progress-reach-transaction-value,
  .bp-progress-reach-cpc,
  .bp-progress-reach-final {
    padding: 20px;

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    .bp-progress-reach-website-perform-content {
      padding: 3rem 5rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 26px #00000014;
      border-radius: 21px;
      opacity: 1;
      width: 768px;

      @media screen and (max-width: 700px) {
        width: 100%;
        padding: 56px 24px;
      }

      .bp-progress-reach-website-perform-content-title {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: $primary-color;

        @media screen and (max-width: 700px) {
          font-size: 16px;
        }
      }

      .bp-progress-reach-website-perform-content-description {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 32px;

        span {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        }

        @media screen and (max-width: 700px) {
          font-size: 22px;
        }
      }

      .bp-progress-reach-website-perform-content-info {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-top: 26px;
      }

      .bp-progress-reach-website-perform-content-btns {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        @media screen and (max-width: 700px) {
          justify-content: center;
          margin: auto;
        }

        .bp-progress-reach-website-perform-next-btn {
          height: 44px;
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          background-color: #0096de !important;
          text-transform: capitalize;
        }

        .not-sure-link {
          text-decoration: underline 2px;
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          margin-left: 10px;
          color: #0096de !important;
          font-size: 18px;
        }
      }

      .slider-label {
        flex-wrap: wrap;
        font-size: 27px;

        @media screen and (max-width: 700px) {
          font-size: 22px;
        }
      }
    }

    .bp-progress-reach-website-perform-budget-suggestion {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
    }
  }

  .bp-progress-reach-summary {
    padding: 20px;

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    .bp-progress-reach-summary-content {
      padding: 3rem 5rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 26px #00000014;
      border-radius: 21px;
      opacity: 1;
      width: 694px;

      @media screen and (max-width: 700px) {
        width: 100%;
        padding: 56px 24px;
      }

      .bp-progress-reach-summary-content-title {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: $primary-color;
        margin-top: 63px;

        @media screen and (max-width: 700px) {
          font-size: 16px;
        }
      }

      .bp-progress-reach-summary-content-description {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 32px;

        @media screen and (max-width: 700px) {
          font-size: 22px;
        }

        span {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        }
      }

      .bp-progress-reach-summary-content-btns {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        @media screen and (max-width: 700px) {
          justify-content: center;
          margin: auto;
        }

        .bp-progress-reach-summary-next-btn {
          height: 44px;
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          background-color: #0096de !important;
          text-transform: capitalize;
        }
      }
    }
  }
}
