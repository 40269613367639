@import "../../scss/colors";

.hp-slider-container {
    .slider-label {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 38px;

        .value-prefix {
          display: flex;
            margin-right: 0;
            color: #000;
        }

        .value-postfix {
            display: flex;
            margin-left: .5rem;
            color: #000;
        }
    }
      .slider-range {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $primary-color;
        font-size: 14px;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      }


    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: $primary-color;
        color: $primary-color;
      }
}
.option-slider-label {
  .max-value-label {
    float: right;
    color: #000;
  }

  .min-value-label {
    color: #000;
  }
}
