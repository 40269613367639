.hp-rec-wrap {
  .hp-rec-page-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .hp-rec-page-title-left {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      display: flex;
    }

    .hp-rec-page-title-right {
      display: flex;
      justify-content: center;

      .hp-btn-v1 {
        margin-top: 0 !important;
      }

      .hp-back-link {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: #0096d6;
        cursor: pointer;

        span {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .hp-rec-score {
    margin-top: 1rem;
    .hp-assessment-scores-section-bar-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 0.5rem;
    }

    .hp-assessment-scores-section-bar {
      display: flex;
      width: 100%;
      height: 7px;
      background: #f4f4f4 0% 0% no-repeat padding-box;
      position: relative;

      .hp-assessment-scores-section-bar-fill {
        display: flex;
        position: absolute;
        height: 7px;
        width: 70%;
        background: transparent linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%)
          0% 0% no-repeat padding-box;
      }
    }

    .hp-report-score {
      display: flex;
      flex-direction: row;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 12px;
      margin-bottom: 0.5rem;

      .hp-report-score-left {
        display: flex;
        flex-basis: 50%;
        color: #707070;
      }

      .hp-report-score-right {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;
      }
    }

    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }

  .hp-rec-title {
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 1rem;
  }

  .hp-rec-desc {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin-top: 1rem;
  }

  .hp-rec-content-wrap {
    .hp-rect-content-unit {
      margin-top: 1.5rem;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 11px;
      flex-direction: column;
      display: flex;
      .hp-rec-content-title {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;
        background-color: #a8e6ff;
        height: 44px;
        padding: 0.5rem 1rem;
        border-radius: 11px 11px 0 0;
      }

      .hp-rec-content-para1 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        padding: 1rem;
        padding-top: 2rem;
        flex-direction: column;
      }
    }
  }

  .hp-rec-sitesList {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;

    .hp-rec-sitesList-title {
      display: flex;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;
      width: 100%;
      text-transform: uppercase;
    }

    .hp-rec-sitesList-item {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      width: 100%;

      .hp-rec-site {
        display: flex;
        height: 44px;
        width: 100%;
        box-shadow: 0px 0px 11px #003d571c;
        border-radius: 6px;
        padding: 0.5rem 1.5rem;
      }

      .hp-rec-site-see-result {
        display: flex;
        margin-top: 1rem;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        span {
          display: flex;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 18px;
        }

        .hp-rec-site-icon {
          color: #0096d6;
          margin-left: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;

          i {
            margin-left: 10px;
          }

          span {
            color: #0096d6;
          }
        }
      }
    }
  }
  .hp-rec-default-section {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
}
