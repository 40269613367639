.hp-landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .hp-landing-page-content {
    display: flex;
  }

  .hp-landing-page-text {
    display: flex;
    font-size: 18px;
    color: #0096d6;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    @media screen and (min-width: 576px) {
      font-size: 32px;
    }
  }

  .switch-lang-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;

    select {
      appearance: none;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #01527531;
      border-radius: 6px;
      width: 206px;
      height: 33px;
      text-align: center;
      text-align-last: center;
    }
  }

  .disclaimer-text {
    margin-top: 0.3rem;
    width: 210px;
    text-align: center;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #ff0000;
  }

  .select-lan-btn {
    margin-top: 0.3rem;
    color: #0096d6;
    cursor: pointer;
  }
}

.disclaimer-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 500px;
  margin-top: 1rem;

  @media screen and (max-width: 575px) {
    width: 300px;
  }
  .disclaimer-title {
  }

  .disclaimer-content {
  }
}
