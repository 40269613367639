.hp-report-page {
  .hp-report-top {
    margin-top: 2rem;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    .hp-report-top-left-panel {
      @media screen and (max-width: 575px) {
        border-bottom: 1px solid #efefef;
        padding-bottom: 1rem;
      }

      @media screen and (min-width: 576px) {
        flex-basis: 40%;
      }

      .hp-report-page-link1 {
        text-transform: uppercase;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.95px;
      }

      .hp-report-page-link2 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 29px;
      }

      .hp-report-page-link3 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #cccccc;
        line-height: 18px;
      }
    }

    .hp-report-top-right-panel {
      @media screen and (max-width: 575px) {
        margin-top: 1rem;
      }

      @media screen and (min-width: 576px) {
        flex-basis: 60%;
      }

      .hp-report-heading {
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 22px;
        line-height: 26px;

        @media screen and (max-width: 575px) {
          font-size: 18px;
          margin-bottom: 0.5rem;
        }
      }

      .hp-report-content {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 29px;
      }
    }
  }

  .report-image {
    width: 33px;
    height: 33px;
  }

  .hp-report-survey {
    .hp-report-survey-heading {
      border-bottom: 1px solid #efefef;
      padding-bottom: 0.5rem;

      .hp-report-survey-title {
        flex-basis: 70%;
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 22px;

        @media screen and (max-width: 575px) {
          font-size: 18px;
        }
      }

      .hp-report-survey-download-link {
        flex-basis: 30%;
        text-align: right;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        cursor: pointer;

        @media screen and (max-width: 575px) {
          font-size: 14px;
        }
      }
    }

    .hp-report-survey-desc {
      @media screen and (max-width: 575px) {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
      }
    }

    .hp-report-survey-chart {
      margin-top: 1.5rem;

      .hp-report-partner-position {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        letter-spacing: 0.95px;
        line-height: 21px;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }

    .hp-report-survey-tabs {
      flex-basis: 100%;
      width: 100%;
      padding: 0 3rem;
      justify-content: space-evenly;
      border-bottom: 1px solid #efefef;

      @media screen and (max-width: 575px) {
        margin-top: 1rem;
        padding: 0 1rem;
      }

      .hp-report-survey-tab {
        justify-content: center;
        text-transform: uppercase;
        align-items: center;

        cursor: pointer;

        img {
          width: 80px;
          height: 76px;

          @media screen and (max-width: 575px) {
            width: 50px;
            height: 50px;
          }
        }

        div {
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
          letter-spacing: 0.95px;
          margin-top: 0.5rem;
          justify-content: center;
          color: #bfbfbf;
          align-items: center;
          text-align: center;

          @media screen and (max-width: 575px) {
            font-size: 16px;
          }
        }

        .hp-report-tab-text {
        }
      }

      .hp-tab-service {
        width: 33%;
        div {
          width: 70%;
        }
      }

      .hp-tab-digital {
        width: 25%;
      }

      .hp-report-survey-tab-active {
        border-bottom: 5px solid #0096d6;

        .hp-report-tab-text-active {
          color: #000;
        }
      }
    }

    .hp-report-survey-tab-content {
      .hp-report-survey-tab-content-heading {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 0.6rem;

        @media screen and (max-width: 575px) {
          font-size: 14px;
        }

        .hp-report-chart-section-name {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 18px;
          margin: 0 5px;

          @media screen and (max-width: 575px) {
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .hp-report-guage-chart-wrap {
        justify-content: center;

        .guage-chart {
          @media screen and (max-width: 575px) {
            width: 147px;
          }
        }

        .hp-report-guage-title {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 20px;
          display: flex;
          justify-content: center;
        }

        .hp-report-guage-title-score {
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .hp-report-switch {
    justify-content: center;
    padding: 2rem 0;

    .hp-report-switch-digital-text-wrap {
      flex-direction: column;
      text-align: right;
      width: 65%;
      height: 100%;
      justify-content: center;

      @media screen and (max-width: 575px) {
        width: 55%;
      }
    }

    .hp-report-switch-service-text-wrap {
      flex-direction: column;
      text-align: left;
      width: 65%;
      height: 100%;
      justify-content: center;
      margin-left: 35%;

      @media screen and (max-width: 575px) {
        margin-left: 49%;
      }
    }

    .hp-switch-info-disabled {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 12px;
      color: #626262;
      z-index: 1;
    }

    .hp-switch-title {
      text-transform: uppercase;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;
      z-index: 1;
    }

    .hp-switch-title1 {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 22px;
      z-index: 1;
    }

    .hp-switch-info {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 12px;
      color: #fff;
      z-index: 1;
    }

    .hp-report-switch-digital {
      cursor: pointer;
      flex-basis: 50%;
      height: 136px;
      border-radius: 29px;
      background: #e3e3e3 0% 0% no-repeat padding-box;

      .triangle {
        clip-path: polygon(56% 51%, 100% 84%, 100% 13%);
        background: #002dad;
        height: 25px;
        width: 25px;
        position: absolute;
        left: 25%;
        @media (min-width: 360px) {
          left: 25%;
        }
        @media (min-width: 375px) {
          left: 26%;
        }
        @media (min-width: 400px) {
          left: 27%;
        }
        @media (min-width: 411px) {
          left: 28%;
        }
        @media (min-width: 441px) {
          left: 27%;
        }
        @media (min-width: 576px) {
          left: 34%;
        }
        @media (min-width: 600px) {
          left: 35%;
        }
        @media (min-width: 768px) {
          left: 38%;
        }
        @media (min-width: 992px) {
          left: 41%;
        }
        @media (min-width: 1024px) {
          left: 41%;
        }
        @media (min-width: 1200px) {
          left: 42.8%;
        }
        @media (min-width: 1440px) {
          left: 43.6%;
        }
      }

      .semi-circle {
        clip-path: circle(50% at 100% 50%);
        background-color: #2ca0d5;
        height: 136px;
        width: 136px;
        position: absolute;
        right: 50%;

        .semi-circle-middle {
          clip-path: circle(35% at 100% 50%);
          background-color: #0183ef;
          height: 136px;
          width: 136px;
          .semi-circle-inner {
            clip-path: circle(20% at 100% 50%);
            background-color: #002dad;
            height: 136px;
            width: 136px;
          }
        }
      }
    }

    .hp-report-switch-service {
      cursor: pointer;
      flex-basis: 50%;
      height: 136px;
      background: #e3e3e3 0% 0% no-repeat padding-box;
      border-radius: 29px;

      .triangle {
        clip-path: polygon(56% 51%, 100% 84%, 100% 13%);
        transform: rotate(180deg);
        background: #002dad;
        height: 25px;
        width: 25px;
        position: absolute;
        right: 25%;
        @media (min-width: 360px) {
          right: 25%;
        }
        @media (min-width: 375px) {
          right: 26%;
        }
        @media (min-width: 400px) {
          right: 27%;
        }
        @media (min-width: 411px) {
          right: 28%;
        }
        @media (min-width: 441px) {
          right: 27%;
        }
        @media (min-width: 576px) {
          right: 34%;
        }
        @media (min-width: 600px) {
          right: 35%;
        }
        @media (min-width: 768px) {
          right: 38%;
        }
        @media (min-width: 992px) {
          right: 41%;
        }
        @media (min-width: 1024px) {
          right: 41%;
        }
        @media (min-width: 1200px) {
          right: 42.8%;
        }
        @media (min-width: 1440px) {
          right: 43.6%;
        }
      }

      .semi-circle {
        clip-path: circle(50% at 0% 50%);
        background-color: #2ca0d5;
        height: 136px;
        width: 136px;
        position: absolute;
        left: 50%;
        .semi-circle-middle {
          clip-path: circle(35% at 0% 50%);
          background-color: #0183ef;
          height: 136px;
          width: 136px;
          .semi-circle-inner {
            clip-path: circle(20% at 0% 50%);
            background-color: #002dad;
            height: 136px;
            width: 136px;
          }
        }
      }
    }

    .hp-switch-active {
      background: transparent
        linear-gradient(105deg, #aae6ff 0%, #a2e2fd 18%, #0096d6 100%) 0% 0%
        no-repeat padding-box !important;
      box-shadow: -7px 7px 10px #f4f4f4;
    }
  }
}

//TOOLKIT STYLES

.hp-toolkit-section {
  .hp-toolkit-section-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
  }

  .hp-toolkit-section-desc {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 1.5rem;
  }

  .hp-toolkit-sub-section {
    margin-top: 0rem;
  }

  .hp-toolkit-video-wrap {
    margin-top: 1rem;
    border-bottom: 1px solid #efefef;
    padding-bottom: 2rem;
    .hp-toolkit-video-tile {
      .hp-toolkit-video-img {
        width: 100%;
        height: 185px;
        // background: url("../../assets/images/video_bg.png") center no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 6px;
      }

      .hp-toolkit-video-title {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-top: 1rem;
        border-bottom: 1px solid #efefef;
        padding-bottom: 2rem;
        .hp-toolkit-content-tile {
          margin-top: 1rem;
          .hp-toolkit-content-img {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 57.5px;
            height: 55px;
          }

          .hp-toolkit-content-img-pdf {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 47.5px;
            height: 55px;
          }

          .img-ppt {
            background: url("../../assets/images/report/presentation-1.svg")
              center no-repeat;
            background-size: cover;
          }

          .img-audio {
            background: url("../../assets/images/report/music-folder-1.svg")
              center no-repeat;
            background-size: cover;
          }

          .img-file {
            background: url("../../assets/images/report/document-1.svg") center
              no-repeat;
            background-size: cover;
          }

          .img-pdf {
            background: url("../../assets/images/report/img-pdf.svg") center
              no-repeat;
            background-size: cover;
          }

          .hp-toolkit-content-title {
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            font-size: 14px;
            margin-top: 1rem;
          }

          .hp-toolkit-content-desc {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 14px;
            margin-top: 0.7rem;
          }

          .hp-toolkit-content-link {
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            font-size: 14px;
            color: #0096d6;
            text-decoration: underline;
            margin-top: 0.5rem;
          }
        }
      }

      .hp-toolkit-video-desc {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-top: 0.7rem;
      }
    }
  }

  .hp-toolkit-content-wrap {
    margin-top: 1rem;
    border-bottom: 1px solid #efefef;
    padding-bottom: 2rem;
    .hp-toolkit-content-tile {
      margin-top: 1rem;
      .hp-toolkit-content-img {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 57.5px;
        height: 55px;
      }

      .img-ppt {
        background: url("../../assets/images/report/presentation-1.svg") center
          no-repeat;
        background-size: cover;
      }

      .img-audio {
        background: url("../../assets/images/report/music-folder-1.svg") center
          no-repeat;
        background-size: cover;
      }

      .img-file {
        background: url("../../assets/images/report/document-1.svg") center
          no-repeat;
        background-size: cover;
      }

      .hp-toolkit-content-title {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-top: 1rem;
      }

      .hp-toolkit-content-desc {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin-top: 0.7rem;
      }

      .hp-toolkit-content-link {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #0096d6;
        text-decoration: underline;
        margin-top: 0.5rem;
      }
    }
  }

  .hp-toolkit-more-link {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
    justify-content: flex-end;
    text-align: right;

    a {
      color: #0096d6;

      &:hover {
        color: #0096d6;
      }
    }

    .hp-toolkit-more-link-icon {
      font-size: 12px;
      align-items: center;
      display: flex;
      margin-left: 0.3rem;
      color: #0096d6;
    }
  }
}

.hp-report-error {
  display: flex;
  align-items: center;
  flex-direction: column;

  .hp-error-title {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: #0096d6;

    @media screen and (max-width: 575px) {
      font-size: 22px;
    }
  }

  .hp-error-desc {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }
}
.hp-report-not-found-title {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-align: center;
}

.peer-rating-disclaimer {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 12px;
  width: 100%;

  .label-wrap {
    width: 50%;

    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  .disclaimer-label {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  }
}

.hp-tab {
  background: transparent
    linear-gradient(98deg, #aae6ff 0%, #a2e2fd 18%, #0096d6 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -7px 7px 10px #f4f4f4;
  border-radius: 14px;
  margin: 30px 0;
  text-align: center;
  padding: 20px;

  .hp-switch-title {
    text-transform: uppercase;
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    font-size: 18px;
    z-index: 1;
  }

  .hp-switch-title1 {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 22px;
    z-index: 1;
  }

  .hp-switch-info {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #fff;
    z-index: 1;
  }
}

//Hp custom By Hanish
.hp-csv-download {
  text-align: right;
}
