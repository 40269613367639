.feature-floating-button {
  position: fixed;
  bottom: 10px;
  z-index: 9999;
  @media screen and (min-width: 576px) {
    left: 50%;
    transform: translateX(60%);
  }
  @media screen and (max-width: 575px) {
    right: 0px;
  }

  .feature-floating-buttons-wrap {
    display: flex;
    width: 240px;
    height: 65px;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 13px 26px #00000072;
    background-color: #fff;
    border-radius: 0 0 0 10px;

    @media screen and (max-width: 575px) {
      width: 240px;
      height: 65px;
    }
    .feature-floating-buttons-wrap-text {
      text-transform: uppercase;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;

      @media screen and (max-width: 575px) {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 14px;
      }
    }

    .feature-floating-buttons-wrap-buttons {
      display: flex;
      flex-direction: row;
      justify-content: inherit;
      width: 200px;
    }

    .feature-floating-button-icon {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .feature-floating-modal {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 3px 16px #003e586e;
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 10px;
    width: 400px;
    transform: translateX(-64%);

    @media screen and (max-width: 575px) {
      width: 280px;
      transform: translateX(-20%);
    }

    .feature-floating-modal-content {
      .feature-floating-modal-content-row {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        cursor: pointer;
      }
      .feature-modal-item-border-bottom {
        border-bottom: 1px solid #e3e3e3;
      }

      .feature-floating-modal-content-item {
        display: flex;
        align-items: flex-start;
      }

      .feature-floating-modal-content-img-wrap {
      }

      .feature-floating-modal-content-text {
        margin-left: 0.5rem;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-transform: uppercase;

        @media screen and (max-width: 575px) {
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 12px;
        }
      }

      .feature-floating-modal-content-desc {
        margin-top: 0.5rem;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;

        @media screen and (max-width: 575px) {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 12px;
        }
      }

      .feature-floating-modal-content-img {
        margin-top: 0.5rem;
        span {
          i {
            color: #0096d6;
          }
        }
      }
    }
  }
}

.floating-text {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.unlocked-label {
  font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
  font-size: 12px;
  background-color: #0070a0;
  height: 20px;
  position: absolute;
  top: -20px;
  left: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 10px 10px 0 0;
}
