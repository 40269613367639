.radar {
  circle {
    fill: #e2f6ff;
  }
  .scale {
    stroke: #beebff;
    stroke-width: 1.5px;
  }

  .caption {
    fill: #40afde;
    font-weight: 900;
    font-size: 12px;
  }

  .shape {
    fill-opacity: 0;
    stroke-width: 3px;

    &:hover {
      fill-opacity: 0;
    }
  }

  .indication {
    padding-top: 20px;
    p {
      font-size: 14px;
      color: #000000;
      margin: 0;
      padding: 0;
    }
    .blue-circle {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: #0a00ff;
      margin-left: 10px;
    }
    .green-circle {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: #08b197;
      margin-left: 10px;
    }
  }
}
.tabs-container {
  display: flex;
  justify-content: center;
  // margin-top: 30px;
  // margin-bottom: 35px;
  .tabs {
    display: flex;
    flex-direction: column;
    font-size: 12px;

    .tab1 {
      height: 25px;
      width: 80px;
      background-color: #deeaef;
      padding: 5px 10px;
      font-size: 12px;
    }
    .tab2 {
      height: 25px;
      width: 80px;
      background-color: #a8e6ff;
      padding: 5px 10px;
      font-size: 12px;
    }
    .tab3 {
      height: 25px;
      width: 80px;
      background-color: #4ecaff;
      padding: 5px 10px;
      font-size: 12px;
    }
    .tab4 {
      height: 25px;
      width: 80px;
      background-color: #39b2e5;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .chartjs-render-monitor {
    height: 220px !important;
    width: 415px !important;
  }
}
