@import "./../../../scss/colors";

.planner-box-shadow {
  box-shadow: 0px 0px 40px #00000019;
}

.hp-reach-budget-recalculate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  .recalculate-btn {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    background-color: #0096de !important;
    color: #fff;
    padding: 0.75rem 2rem;
    cursor: pointer;
  }
}

.budget-selection-wrap {
  .budget-selecion-input {
  }

  .budget-selecion-label {
    margin-left: 0.5rem;
  }

  .budget-selecion-input:checked ~ .budget-selecion-label::before {
    background-color: #0096d6;
    border-color: #0096d6;
  }
}
.bp-reach-budget-recommendation {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  //box-shadow: 0px 0px 40px #00000019;
  border-radius: 11px;
  opacity: 1;
  width: 768px;

  @media screen and (max-width: 871px) {
    width: 100%;
  }

  .bp-reach-budget-recommendation-collapsed {
    margin: auto;

    @media screen and (max-width: 672px) {
      width: 100%;
    }

    .bp-reach-budget-recommendation-collapsed-title {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 32px;
      margin-bottom: 4px;
    }

    .bp-reach-budget-recommendation-collapsed-description {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      @media screen and (max-width: 672px) {
        width: 100%;
      }
    }
  }

  .bp-reach-budget-recommendation-expanded {
    width: 672px;
    margin: auto;

    @media screen and (max-width: 672px) {
      width: 100%;
    }

    .bp-reach-budget-recommendation-expanded-title {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 32px;
      margin-bottom: 4px;
    }

    .bp-reach-budget-recommendation-expanded-description {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      width: 100%;
      @media screen and (max-width: 672px) {
        width: 100%;
      }
    }

    .bp-reach-budget-recommendation-expanded-table {
      overflow-x: scroll;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track {
        display: none;
      }

      table {
        width: 100%;

        .tb-header-row {
          border-bottom: 1px solid #cccccc;
          border-top: 1px solid #cccccc;

          td {
            text-align: left;
            font-size: 14px;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            width: fit-content;
            min-width: 60px;
            padding: 8px 0px;

            &:nth-child(1) {
              text-align: center;
              width: 250px;
              margin-top: 10px;
              margin-bottom: 10px;
              background-color: #ffffff;
              left: 0;
              position: sticky;
              z-index: 9999;

              @media screen and (max-width: 575px) {
                min-width: 150px;
                width: 210px;
              }

              &::before {
                //box-shadow: 10px 0px 15px -15px inset;
                content: " ";
                height: 100%;
                top: 0;
                left: -15px;
                position: absolute;
                width: 15px;
              }

              &::after {
                //box-shadow: 10px 0px 15px -15px inset;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                right: -15px;
                width: 15px;
              }
            }

            .md-form {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }

        .tb-row-disabled {
          color: #cccccc;

          td {
            color: #cccccc;

            input {
              color: #cccccc !important;
              border-bottom: 1.5px solid #cccccc !important;
            }
          }
        }

        .tb-data-row {
          td {
            font-size: 14px;
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            padding: 0px;

            @media screen and (max-width: 575px) {
              font-size: 12px;
            }

            &:nth-child(1) {
              font-size: 16px;
              font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
              margin-top: 10px;
              margin-bottom: 10px;
              background-color: #ffffff;
              left: 0;
              position: sticky;
              z-index: 9999;
              background: #ffffff 0% 0% no-repeat padding-box;
              //box-shadow: 1px 1px 1px #00000015;

              @media screen and (max-width: 575px) {
                font-size: 14px;
              }

              &::before {
                //box-shadow: 10px 0px 15px -15px inset;
                content: " ";
                height: 100%;
                top: 0;
                left: -15px;
                position: absolute;
                width: 15px;
              }

              &::after {
                //box-shadow: 10px 0px 15px -15px inset;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                right: -15px;
                width: 15px;
              }
            }

            .md-form {
              margin-top: 10px;
              margin-bottom: 10px;
              margin-left: 4px;
            }

            input {
              border-bottom: 1.5px solid $primary-color;
              color: $primary-color;
              font-size: 14px;
              transition: none;
              padding: 0px;
              text-align: left;

              &:focus,
              &:active {
                border: none;
                border-bottom: 1.5px solid $primary-color;
                box-shadow: none;
              }

              &:disabled {
                border-bottom: 1.5px solid #cccccc;
              }
            }
          }
        }

        .tb-total-header-row {
          margin-top: 20px;

          td {
            text-align: left;
            font-size: 14px;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            width: fit-content;
            min-width: 60px;
            padding: 8px 0px;

            &:nth-child(1) {
              text-align: center;
              width: 250px;
              margin-top: 10px;
              margin-bottom: 10px;
              background-color: #ffffff;
              left: 0;
              position: sticky;
              z-index: 9999;
              @media screen and (max-width: 575px) {
                width: 210px;
                min-width: 150px;
              }
              //box-shadow: 1px 1px 1px #00000015;

              &::before {
                //box-shadow: 10px 0px 15px -15px inset;
                content: " ";
                height: 100%;
                top: 0;
                left: -15px;
                position: absolute;
                width: 15px;
              }

              &::after {
                //box-shadow: 10px 0px 15px -15px inset;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                right: -15px;
                width: 15px;
              }
            }
          }
        }

        .tb-total-data-row {
          border-bottom: 1px solid #cccccc;
          border-top: 1px solid #cccccc;
          td {
            padding-left: 5px !important;
            text-align: left;
            font-size: 14px;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            width: fit-content;
            min-width: 60px;
            padding: 6px 0px;
            margin: auto;
            color: $primary-color;
            padding: 8px 0px;

            &:nth-child(1) {
              text-align: center;
              width: 250px;
              margin-top: 10px;
              margin-bottom: 10px;
              background-color: #ffffff;
              left: 0;
              position: sticky;
              z-index: 9999;
              @media screen and (max-width: 575px) {
                width: 210px;
                min-width: 150px;
              }
              //box-shadow: 1px 1px 1px #00000015;

              &::before {
                //box-shadow: 10px 0px 15px -15px inset;
                content: " ";
                height: 100%;
                top: 0;
                left: -15px;
                position: absolute;
                width: 15px;
              }

              &::after {
                //box-shadow: 10px 0px 15px -15px inset;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                right: -15px;
                width: 15px;
              }
            }
          }
        }
      }
    }

    .bp-reach-budget-recommendation-expanded-disclaimer {
      padding: 44px;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      border-bottom: 1px solid #cccccc;

      @media screen and (max-width: 745px) {
        padding: 22px;
      }

      .bp-reach-budget-recommendation-expanded-disclaimer-title {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .bp-reach-budget-recommendation-expanded-disclaimer-description {
        font-size: 12px;
      }
    }

    .bp-reach-budget-recommendation-expanded-budget-summary {
      padding: 44px;
      border-bottom: 1px solid #cccccc;
      display: flex;

      @media screen and (max-width: 574px) {
        padding: 22px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .bp-reach-budget-recommendation-expanded-budget-summary-side {
        padding: 26px;
        display: flex;
        flex-direction: column;
        width: 50%;

        @media screen and (max-width: 574px) {
          width: 100%;
        }

        .bp-reach-budget-recommendation-expanded-budget-summary-side-title {
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
        }

        .bp-reach-budget-recommendation-expanded-budget-summary-side-amount {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 32px;
        }

        .bp-reach-budget-recommendation-expanded-budget-summary-side-items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          .bp-reach-budget-recommendation-expanded-budget-summary-side-item {
            text-align: left;

            .bp-reach-budget-recommendation-expanded-budget-summary-side-item1 {
              font-size: 18;
              font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            }

            .bp-reach-budget-recommendation-expanded-budget-summary-side-item2 {
              font-size: 18;
              font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            }
          }
        }
      }
    }

    .bp-reach-budget-recommendation-expanded-budget-confirm {
      display: flex;
      flex-direction: row;
      padding: 40px 10px;

      @media screen and (max-width: 574px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 10px;
      }

      .bp-reach-budget-recommendation-expanded-budget-confirm-question {
        width: 50%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 32px;

        @media screen and (max-width: 574px) {
          font-size: 22px;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }

      .bp-reach-budget-recommendation-expanded-budget-confirm-btn-area {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 574px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .bp-reach-budget-recommendation-expanded-budget-confirm-btn {
          height: 44px;
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          background-color: #0096de !important;
          text-transform: capitalize;
          width: fit-content;
        }

        .bp-reach-budget-recommendation-expanded-budget-confirm-recalculate {
          text-decoration: underline 2px;
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          margin-left: 10px;
          color: #0096de !important;
          font-size: 18px;
        }
      }
    }
  }
}
.planner-expanded {
  padding: 2rem 2rem;

  .planner-expanded-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-bottom: 1.5rem;

    .bold-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 32px;
    }
  }
}
