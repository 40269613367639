@import "./scss/colors";
@import "./scss/fonts";
@import "./scss/variables";
@import "./scss/baseline";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.hp-layout-wrap {
  background-color: #d8d8d8;
  height: 100%;
  min-height: 768px;
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (min-width: 568px) {
    min-height: 960px;
    background: url("../src/assets/images/background-latest-compressed.jpg")
      no-repeat center;
    background-attachment: fixed;
  }
}

.hp-layout {
  max-width: 768px;
  background-color: $white;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 568px) {
    min-height: 960px;
  }
  @media screen and (max-width: 575px) {
    min-height: 768px;
  }
}

.hp-regular {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
}

.hp-lite {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
}

.hp-bold {
  font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
}

.hp-22 {
  font-size: 22px;
}

.hp-18 {
  font-size: 18px;
}

.hp-12 {
  font-size: 12px;
}

.hp-10 {
  font-size: 10px;
}

.hp-14 {
  font-size: 14px;
}

.hp-32 {
  font-size: 32px;
}

.hp-50 {
  flex-basis: 50%;
}

.hp-primary-color {
  color: $primary-color;
}

.hp-cta-v1 {
  background-color: #0096d6;
  border-radius: 30px;
  color: #fff;
  padding: 10px 35px;
  cursor: pointer;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  height: 60px;
  @media screen and (max-width: 576px) {
    height: 44px;
    font-size: 22px;
    padding: 10px 20px;
  }
}

.hp-cta-v1:hover {
  text-decoration: none;
  color: #fff;
}

.hp-cta-v1-full-width {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hp-cta-v2 {
  background-color: #0096d6;
  border-radius: 30px;
  color: #fff;
  padding: 10px 35px;
  cursor: pointer;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  height: 60px;
  width: 78%;
  @media screen and (max-width: 576px) {
    height: 44px;
    font-size: 22px;
    padding: 10px 20px;
  }
}

.hp-cta-v2:hover {
  text-decoration: none;
  color: #fff;
}

.hp-cta-v2-full-width {
  width: 100%;
  display: flex;
  justify-content: center;
}

.hp-section-title-v3 {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 32px;

  @media screen and (max-width: 575px) {
    font-size: 22px;
  }
}

.hp-cta-v1-disabled {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
  background-color: #dfecf2;
  color: #b2c3d1;
  border-radius: 30px;
  padding: 10px 35px;
  cursor: pointer;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  height: 60px;
  text-align: center;
  line-height: 25px;
  cursor: default !important;
  @media screen and (max-width: 576px) {
    height: 44px;
    font-size: 18px;
    padding: 10px 20px;
  }
}

.bread-crumb {
  font-size: 14px;
  a {
    margin-right: 3px;
    color: #000000;
  }
  span {
    text-decoration: underline;
    margin-left: 3px;
  }
}

.loading-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-wrap-full {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.7;
  z-index: 99999;
}

.slick-dots li.slick-active button:before {
  color: #0096d6 !important;
}

.slick-dots li.slick-active button:after {
  color: #cccccc !important;
}

.ytp-title-text {
  display: none !important;
}
