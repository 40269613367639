@import "./../../../scss/colors";

.complete-planner-container {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

  .complete-planner-data {
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
  }
  .complete-planner-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-bottom: 2rem;

    .bold-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    }
  }

  .complete-planner-summary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    .show-summary-title {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 570px;
      text-align: center;

      @media screen and (max-width: 575px) {
        width: 100%;
        font-size: 22px;
      }
    }
    .complete-planner-cta {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      width: 110px;
      height: 45px;
      color: #fff;
      background-color: $primary-color;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
      margin-top: 1rem;
    }
  }
}

.complete-planner-table-wrap {
  display: block;
  width: 100%;
  .cp-table {
    width: 100%;
  }
  .cp-t-header {
    display: block;
    background-color: $primary-color;
    color: #fff;
    border-radius: 10px 10px 0 0;
    .c1 {
      width: 15%;
    }
    .c2 {
      width: 5%;
    }
    .c3 {
      width: 6%;
    }
    .cc {
      width: 17%;
    }
    .c-total {
      width: 6%;
      .row-2 {
        text-transform: uppercase;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 14px;
      }
    }
    .one-row-header {
      display: inline-block;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      padding: 0.5rem 1rem;
    }
    .two-row-header {
      display: inline-block;
      padding: 0.5rem 0.5rem;
      .row-1 {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
      }
      .row-2 {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
        text-align: center;
        div {
          display: inline-block;
          width: 33%;
        }

        .row-rt {
          padding-right: 0.4rem;
        }
      }
    }
  }

  .cp-t-body {
    .cp-t-section-header {
      display: block;
      padding: 1rem 1rem;
      .t-section-title {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        display: inline-block;
      }

      .t-section-actions {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        display: inline-block;
        float: right;
        margin-right: 10%;

        .t-edit-label {
          display: inline-block;
          margin-right: 0.5rem;
          text-decoration: underline;
        }

        .t-edit-btn {
          display: inline-block;
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 12px;
          color: #fff;
          width: 50px;
          height: 20px;
          text-align: center;
          background: $primary-color 0% 0% no-repeat padding-box;
          border-radius: 22px;
          padding-right: 0.25rem 0.5rem;
          cursor: pointer;
        }
      }
    }
    .cp-t-row {
      display: block;
      padding: 1rem 0;
      position: relative;
      margin-left: 2.25rem;
      margin-top: 1rem;
      .selected-icon {
        position: absolute;
        left: -2rem;
      }

      .t-r-technology {
        display: inline-block;
        width: 15%;
        text-align: left;
        padding-left: 0.5rem;
        i {
          color: $primary-color;
          padding-right: 0.25rem;
          padding-left: 0.25rem;
        }
      }

      .t-r-pay {
        display: inline-block;
        width: 5%;
        text-align: left;
      }

      .t-r-subscription {
        display: inline-block;
        width: 6%;
        text-align: left;
      }

      .t-r-cell {
        display: inline-block;
        width: 5.66%;
        text-align: left;
      }

      .t-r-total {
        display: inline-block;
        width: 6%;
        text-align: center;
        color: $primary-color;
      }
    }

    .row-selected {
      background: #e8f8ff 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      border-radius: 22px;
    }
  }

  .cp-t-footer {
    display: block;
    margin-top: 1rem;
    .footer-row-1 {
      display: block;
      background-color: $primary-color;
      color: #fff;
      height: 45px;
      .footer-total-label {
        display: inline-block;
        width: 26%;
        text-align: center;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        height: 45px;
      }
      .footer-total-val {
        display: inline-block;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
        width: 5.66%;
        height: 45px;
      }

      .footer-grand-total-val {
        display: inline-block;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
        width: 6%;
        text-align: center;
        height: 45px;
      }
    }
    .footer-row-2 {
      display: block;
      background: #e2e2e2 0% 0% no-repeat padding-box;
      border-radius: 0px 0px 10px 10px;
      height: 50px;
      .footer-empty-cell {
        display: inline-block;
        width: 26%;
      }
      .footer-quarter-label {
        display: inline-block;
        width: 17%;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
      }
      .footer-annual-label {
        display: inline-block;
        width: 6%;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}
