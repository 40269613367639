.hp-timeline-wrap {
  //width: 100%;
  margin: 2rem 4rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .timeline-sections {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    .timeline-section-name {
      position: absolute;
      top: -28px;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
    }

    .section1 {
      left: 12%;
    }
    .section2 {
      left: 35%;
    }
    .section3 {
      left: 62%;
    }
    .section4 {
      left: 87%;
    }
  }
}

.hp-bp-timeline-bar {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 1rem;

  .hp-bp-timeline-bar-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 92%;
    .hp-bp-timeline-bar-gray {
      width: 100%;
      height: 9px;
      background-color: #dbdbdb;
      border-radius: 5px;
      position: absolute;

      @media screen and (max-width: 575px) {
        width: 290px;
      }

      .hp-bp-timeline-bar-blue {
        position: absolute;
        height: 9px;
        background-color: #0096d6;
        border-radius: 5px;
        display: inline-flex;
      }

      .hp-bp-timeline-milestones {
        position: absolute;
        top: -11px;
        display: inline-flex;
        width: 100%;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;

        @media screen and (max-width: 575px) {
          font-size: 12px;
          top: -27px;
        }
      }

      .hp-bp-timeline-points {
        position: absolute;
        display: flex;
        flex-direction: column;
        img.active {
          width: 32px;
          height: 32px;
        }

        img.disabled {
          width: 18px;
          height: 27px;
        }
      }

      .hp-bp-timeline-0 {
        left: 24%;
      }

      .hp-bp-timeline-1 {
        left: 49%;
      }

      .hp-bp-timeline-2 {
        left: 74%;
      }

      .hp-bp-timeline-3 {
        left: 99%;
      }
    }
  }

  .hp-bp-timeline-filled-0 {
    width: 25%;
  }

  .hp-bp-timeline-filled-1 {
    width: 50%;
  }

  .hp-bp-timeline-filled-2 {
    width: 75%;
  }

  .hp-bp-timeline-filled-3 {
    width: 100%;
  }
}
