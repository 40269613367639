.wizard-page {
  .budget-wizard-none {
    display: none !important;
  }

  .hp-layout {
    max-width: 70%;
    width: 70%;

    @media screen and (max-width: 690px) {
      max-width: 95%;
      width: 95%;
    }
  }

  .error-banner {
    height: 6rem;
    background-color: #ffc4c4;
    color: #ff0000;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    padding: 2rem;

    .visible {
      display: block;
    }

    @media screen and (max-width: 690px) {
      margin-left: -3%;
    }
  }

  .budget-planner {
    @media screen and (max-width: 1440px) {
      width: 768px;
    }
  }

  .container {
    @media screen and (max-width: 690px) {
      width: 100%;
      max-width: 100%;
    }

    .wizard-container {
      margin-top: 30px;

      @media screen and (max-width: 337px) {
        width: 100vw;
      }

      .step-header {
        height: 80px;
        margin: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;

        @media screen and (max-width: 823px) {
          margin: 0;
        }

        .header-icon {
          height: 80px;
          width: 80px;

          @media screen and (max-width: 823px) {
            height: 50px;
            width: 50px;
          }

          @media screen and (max-width: 337px) {
            height: 25px;
            width: 25px;
          }
        }

        .header-text {
          padding: 1rem;
          vertical-align: bottom;
          line-height: 3rem;

          @media screen and (max-width: 823px) {
            font-size: 1.3rem;
            line-height: 2rem;
            vertical-align: baseline;
          }

          @media screen and (max-width: 337px) {
            font-size: 0.9rem;
            line-height: 1.2rem;
            vertical-align: baseline;
            padding: 0.2rem;
          }
        }
      }

      .option-card {
        width: 95%;
        border-radius: 25px;
        background-color: #eaf0f2;
        padding: 2rem;
        margin: 1rem 0;

        @media screen and (max-width: 690px) {
          padding: 0.5rem 1rem 1rem 1rem;
          border-radius: 15px;
        }

        @media screen and (max-width: 337px) {
          width: 100vw;
          min-width: 250px;
          margin-left: -3vw;
        }

        &.added {
          background-color: white;
          border-radius: 21px;
          box-shadow: 0px 0px 15px #00000025;

          @media screen and (max-width: 690px) {
            border-radius: 15px;
          }

          .option-card-title {
            color: #0096d6;
          }
        }

        .option-card-top {
          display: flex;

          .option-card-header {
            cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;

            @media screen and (max-width: 690px) {
              flex-basis: 50%;
              justify-content: flex-start;
              display: block;
            }

            .option-card-title {
              display: flex;
              align-items: center;
              font-size: 1.75rem;
              font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
              position: relative;

              @media screen and (max-width: 690px) {
                display: block;
              }
            }

            .option-card-label {
              display: flex;
              align-items: center;
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              font-size: 0.9rem;
              font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

              @media screen and (max-width: 690px) {
                display: block;
                margin: 0;
              }
            }
          }

          .option-card-toggle {
            cursor: pointer;
            float: right;
            display: inline-flex;
            padding: 10px 5px;
            align-items: center;

            @media screen and (max-width: 690px) {
              display: flex;
              align-items: baseline;
              flex-basis: 50%;
              justify-content: flex-end;
            }

            @media screen and (max-width: 690px) {
              margin-top: -15px;
            }

            .custom-control-prefix {
              margin-right: 0.6rem;
              margin-bottom: 0;
              cursor: pointer;
            }

            .custom-switch {
              * {
                cursor: pointer;
              }
            }

            .custom-control-input:checked ~ .custom-control-label::before {
              background-color: #0096d6;
              border-color: #0096d6;
            }

            .custom-control-label::before {
              background-color: #626262;
              border-color: #626262;
            }

            .custom-switch .custom-control-label::after {
              background-color: #fff;
              border-color: #fff;
            }
          }
        }

        .option-card-content {
          margin-top: 2rem;

          .option-sub-title {
            border-bottom: 0.5px solid #dbdbdb;
            font-size: 1.25rem;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            padding-bottom: 1rem;

            .md-only-text {
              @media screen and (max-width: 690px) {
                display: none;
              }
            }

            .option-details {
              cursor: pointer;
              margin-left: 1rem;
              color: #0096d6;
              font-size: 0.9em;
              text-decoration: underline;
              position: relative;
            }

            .option-levels-description {
              height: auto;
              overflow: visible;
              padding: 2rem;
              margin-top: 1.5rem;
              background: #f0f8fc;
              line-height: 1.5;

              ul {
                list-style: none;
                padding-left: 0px;
                line-height: 1.5;
                li {
                  font-size: 1.1rem;
                  font-family: "HP Simplified Lite", Arial, Helvetica,
                    sans-serif;
                }
              }

              &.visible {
                height: auto;
                overflow: visible;
                padding: 1rem;
              }

              .option-levels-info {
                font-weight: 100;
                font-size: 1.1rem;
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
              }

              .level-title {
                letter-spacing: 2px;
                font-size: 1rem;
                font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
              }

              .level-description {
                font-size: 1.1rem;
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
              }
            }
          }

          .option-text {
            margin-top: 1.5rem;
            font-size: 1.25rem;
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          }

          .option-value {
            font-size: 3rem;
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          }

          .option-slider-label {
            width: 100%;
            font-size: 0.9rem;

            .max-value-label {
              float: right;
            }
          }

          .option-value-selecter {
            margin-bottom: 1rem;
            position: relative;

            .info-icon {
              color: #0096d6;
            }

            .value-error-label {
              font-size: 0.9rem;
              color: red;
              margin-bottom: 0.5rem;
            }

            .option-value-select {
              /* Reset */
              appearance: none;
              border: 0;
              outline: 0;
              font: inherit;
              /* Personalize */
              width: 15rem;
              height: 2rem;
              padding: 0 4rem 0 1rem;
              color: black;
              font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
              border-radius: 0.25em;
              box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1);
              cursor: pointer;
              margin-right: 0.4rem;
              background: url("../../../assets/icons/right-arrow.svg") no-repeat
                right 0.8em center / 1.4em;
              background-size: 0.5rem;

              &.error {
                border: 0.01rem solid red;
              }

              /* <option> colors */
              option {
                color: inherit;
                border-bottom: 0.5rem solid #626262;
              }
              /* Remove focus outline */
              &:focus {
                outline: none;
                background-color: white;
              }
              /* Remove IE arrow */
              &::-ms-expand {
                display: none;
              }
            }

            .value-info-icon {
              width: 1.25rem;
              height: 1.25rem;
              padding: 0.3rem;
              background: #0096d6;
              border-radius: 50%;
              margin: 0.25rem;
              margin-left: 0.5rem;
            }
          }

          .option-payment-label {
            font-size: 1.25rem;
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          }
        }
      }

      .next-step-container {
        width: 60%;
        margin: 5rem;
        margin-left: 25%;
        cursor: pointer;

        @media screen and (max-width: 690px) {
          width: 60%;
          margin: 5rem;
          margin-left: 20%;
        }

        .next-step-label {
          font-size: 1.75rem;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

          span {
            font-size: 1.75rem;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          }
        }

        .next-step-icon {
          width: 1.7rem;
          height: 1.7rem;
          padding: 0.4rem;
          background: #0096d6;
          border-radius: 50%;
          margin: 0.25rem;
          margin-left: 0.5rem;
          color: #fff;

          @media screen and (max-width: 690px) {
          }
        }
      }
    }
  }
}
.not-visible {
  display: none;
}

.error-border {
  border: 1px solid red !important;
}

.guided-tour-wrap {
  background-color: #000;
  opacity: 0.7;
  width: 100%;
  height: 300vh;
  position: absolute;
  z-index: 999;
}

.tour-tech-name {
  position: absolute;
  top: -50px;
  z-index: 999;
  width: 350px;
  display: table;
  .tour-tech-content {
    display: table-cell;
  }
}

.tour-tech-highlight {
  background-color: #fff;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 10px solid #0096d6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  word-break: break-all;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tour-tech-text {
  color: #fff;
  font-size: 22px;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tour-tech-cta {
  background-color: #0096d6;
  color: #fff;
  width: 150px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tour-tech-tour {
  position: absolute;
  top: -50px;
  z-index: 999;
  width: 350px;
  display: table;
  left: -70px;

  .custom-control-input {
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #0096d6;
    border-color: #0096d6;
  }
}

.best-value-label {
  position: relative;
}

.img-add {
  width: 125px;
}

.img-details {
  width: 100px;
}

.img-slider {
  width: 120px;
}

.img-budget {
  width: 128px;
  height: 27px;
  aspect-ratio: 1/1;
}

.tour-tech-slider {
  position: absolute;
  top: -20px;
  z-index: 999;
  width: 350px;
  display: table;
  left: -70px;
}

.tour-tech-budget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.budget-text {
  text-align: center;
}

.tooltip-icon {
  position: relative;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 170%;
    box-shadow: 0px 0px 15px #00000025;
    font-size: 10px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  }

  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }
}

.tooltip-icon:hover {
  .tooltip-text {
    visibility: hidden;
    font-size: 10px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  }
}
