.currency-disclaimer {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin: 1rem auto;
  text-align: center;

  .bold-text {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  }

  @media screen and (min-width: 575px) {
    width: 30%;
  }
}
