@import "../../scss/colors";

//Common
.hp-section-title-v1 {
  font-size: 32px;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  color: $primary-color;

  @media (max-width: 575px) {
    font-size: 22px;
  }
}
//common
.hp-section-brief-v1 {
  font-size: 18px;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  color: #000;
}

.hp-section-title-v2 {
  font-size: 18px;
  font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
  color: $primary-color;
  text-transform: uppercase;
}

.hp-section-brief-v2 {
  font-size: 18px;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
}
.react-read-more-read-less .react-read-more-read-less-more {
  font-size: 18px;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.hp-section {
  padding: 2rem;
  @media (max-width: 568px) {
    padding: 1rem;
  }
}

.hp-section-space {
  margin-top: 1rem;
}

.hp-btn-v1 {
  background: #0096d6;
  border-radius: 22px;
  color: #fff;
  width: fit-content;
  padding: 0.5rem 2rem;
  cursor: pointer;
  margin-top: 1.5rem;
}

.hp-home {
  flex-direction: column;
  white-space: pre-line;
  .hp-content-wrap {
    display: flex;
    padding: 1rem 3rem;
    flex-direction: column;
    min-height: 850px;
    @media (max-width: 575px) {
      padding: 0 1rem;
      min-height: 768px;
    }
  }

  .hp-content-block {
    box-shadow: 0px 0px 20px #00577c26;
    border-radius: 11px;
    margin-top: 2rem;
  }
}

.hp-assessment-steps {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .hp-assessment-steps-title {
    font-size: 32px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    color: #0096d6;
    margin-left: 3rem;

    @media screen and (max-width: 575px) {
      font-size: 32px;
      margin-left: 2rem;
    }
  }

  .hp-assessment-step {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    .hp-assessment-img {
      color: #0096d6;
      width: 30.75px;
      height: 30.75px;
    }

    .hp-assessment-step-text {
      margin-left: 1rem;
      .hp-assessment-step-title {
        text-transform: uppercase;
        font-size: 18px;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        color: #0096d6;

        @media (max-width: 575px) {
          font-size: 16px;
        }
      }
      .hp-assessment-step-desc {
        font-size: 18px;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      }
    }
  }
}

.hp-assessment-details {
  color: #fff;
  background-color: #0096d6;
  margin: 0 -3rem !important;
  padding: 2rem 3rem;
  .hp-assessment-details-title {
    font-size: 32px;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  }

  .hp-assessment-details-video {
    //background: url('../../assets/images/video_placeholder.jpeg') center no-repeat;
    //background-position: center;
    //background-size: cover;
    background-color: #000;
    border-radius: 11px;
    height: 400px;
    margin-bottom: 2rem;
    margin-top: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 575px) {
      height: 240px;
      padding-bottom: 1rem;
    }
  }

  .hp-assessment-details-instructions-title {
    font-size: 18px;
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  .hp-assessment-details-instructions {
    font-size: 18px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  }

  .hp-assessment-details-expand-link {
    font-size: 18px;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }
}

.modal-start-assessment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .modal-assessment-title {
    font-size: 22px;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    color: #0096d6;

    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }

  .modal-assessment-body {
    font-size: 18px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    padding: 0 1rem;
  }

  .modal-assessment-cta1 {
    margin-top: 1rem;
    width: 50%;
    div {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
    }
  }

  .modal-assessment-cta2 {
    color: #bfbfbf;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
    text-decoration: underline;
    margin-top: 1rem;
    cursor: pointer;
  }
}

.main-title {
  font-size: 32px;
  color: #0096d6;
}

.assement-survey-title {
  font-size: 18px;
  text-transform: uppercase;
}

.welcome-text {
  font-size: 18px;
  margin-bottom: 5px;
}

.timer {
  display: flex;
  align-items: center;
  margin: 10px 0;
  img {
    height: 31px;
    width: 27px;
    margin-right: 11px;
  }
  p {
    margin: 0;
    color: #0096d6;
    font-size: 18px;
    padding-top: 5px;
  }
}

.go-to-dashboard-btn {
  a {
    color: #fff;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
}

.home-see-benefits-btn {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #fff;
  background: #ff7600 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  text-transform: none;
  cursor: pointer;
  @media screen and (max-width: 575px) {
    padding: 0.25rem 0.25rem;
  }
}

.banner-modal {
  .modal-content {
    border-radius: 20px;
  }
  .modal-body {
    padding: 0 !important;
  }
}

.reassesmentBtn {
  border-radius: 39px;
  background-color: #0096d6;
  color: #fff;
  cursor: pointer;
  padding: 10px 0;
  p {
    margin-bottom: 0;
    text-align: center;
  }
  .head {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 22px;
  }
  .desc {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 13px;
  }
}
