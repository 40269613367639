.hp-report {
  .hp-report-username {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 22px;
  }

  .hp-report-status {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }

    @media screen and (max-width: 576px) {
      border-top: 1px solid #e3e3e3;
      padding-top: 1rem;
      margin-top: 1rem;
    }
  }

  .hp-result-landing-chart-wrap {
    flex-basis: 30%;
  }

  .hp-chart-text {
    color: #626262;
    margin-top: 2rem;
  }

  .hp-report-status__graph {
    display: flex;

    justify-content: center;

    @media screen and (min-width: 576px) {
      margin-top: 2rem;
    }
  }

  .hp-report-status-score {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 576px) {
      flex-basis: 70%;
      margin-left: 3rem;
    }

    .hp-assessment-completed {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 22px;
    }

    .hp-assessment-scores-title {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    .hp-assessment-scores {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
      padding-top: 0.8rem;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 1rem;

      @media screen and (max-width: 576px) {
        border-top: 1px solid #e3e3e3;
        margin-top: 1rem;
        padding-top: 1rem;
      }
    }
  }

  .hp-assessment-scores-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .hp-assessment-scores-section-text {
      display: flex;
      width: 100%;
      text-transform: uppercase;
    }
  }

  .hp-assessment-scores-section-bar-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0.5rem;
  }

  .hp-assessment-scores-section-bar {
    display: flex;
    width: 100%;
    height: 7px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    position: relative;

    .hp-assessment-scores-section-bar-fill {
      display: flex;
      position: absolute;
      height: 7px;
      width: 70%;
      background: transparent linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%)
        0% 0% no-repeat padding-box;
    }
  }

  .hp-report-score {
    display: flex;
    flex-direction: row;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 12px;

    .hp-report-score-left {
      display: flex;
      flex-basis: 50%;
      color: #707070;
    }

    .hp-report-score-right {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
    }
  }

  .hp-assessment-last-updated {
    color: #bfbfbf;
  }

  .donutchart-indicator {
  }
}
.hp-report-landing-mob {
  .donutchart-text-val {
    font-size: 28px;
  }

  .donutchart-text-label {
    font-size: 10px;
  }

  .donutchart-text-percent {
    font-size: 12px;
  }
}

.hp-partnerprofile-block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hp-other-lan-msg-wrap {
  align-items: center;

  .other-lan-msg {
    width: 500px;
    text-align: center;
    margin-top: 3rem;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;

    @media screen and (max-width: 575px) {
      font-size: 14px;
      width: 300px;
    }
  }
}

.hp-tabs-wrapper {
  width: 95%;
  display: flex;
  align-items: center;
  margin: 5px 20px;
  border-bottom: 3px solid #0096d6;

  .tabs {
    background: #0096d6 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    padding: 10px;
    margin-right: 0.5px;
  }
  .tabsInactive {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    color: #000;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    padding: 10px;
    margin-right: 0.5px;
  }
}

.link {
  color: #0096d6;
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.download {
  color: #0096d6;
  font-size: 15px;
}
