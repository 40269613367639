.slider-wrapper {
  height: 319px;
  width: 620px;
  border-radius: 30px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #0096d6;
  color: #fff;

  .slider1-img {
    margin-top: 25px;
    height: 319px;
    width: 220px;
    // object-fit: contain;
    margin-right: 10px;
    overflow: hidden;
  }

  .slider2-img {
    height: 150px;
    width: 220px;
    margin-top: 30px;
  }

  .feature-img {
    position: absolute;
    height: 45px;
    width: 35px;
    top: 0px;
    left: 30px;
  }

  .slider-text {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 3rem;
    line-height: 37px;
    margin-left: 18px;
  }

  .explore-btn {
    border-radius: 20px;
    background-color: #fff !important;
    color: #0096d6;
    width: 180px;
  }

  .social-icon {
    height: 20px;
    text-align: center;
    width: 20px;
  }

  .social-text {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: center;
  }

  .coming-soon {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin-left: 18px;
    margin-top: 4rem;
    margin-bottom: 0;
  }
  .slider-text3 {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 37px;
    margin-left: 18px;
    margin-bottom: 0;
  }
  .sub-text {
    margin-left: 18px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  .slider3-img {
    height: 220px;
    width: 290px;
    margin-top: 30px;
    overflow: hidden;
  }

  .slider4-img {
    height: 260px;
    width: 290px;
    margin-top: 30px;
    overflow: hidden;
  }

  .social-icon2 {
    display: block;
    height: 40px;
    width: 40px;
    margin: 10px 18px;
  }

  .sub-text2 {
    margin-left: 18px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 0;
  }
  .coming-soon-img {
    position: absolute;
    top: 30px;
    left: 20px;
  }

  .slider2-md-img {
    display: none;
  }
  .slider3-md-img {
    display: none;
  }
  .slider4-md-img {
    display: none;
  }

  .slider-text4 {
    font-size: 25px;
    margin-top: 3rem;
    line-height: 37px;
    margin-left: 18px;
    width: 250px;
  }
  .coming-soon-md-img {
    display: none;
  }

  .coming-soon4-md-img {
    display: none;
  }

  .feature-img-1 {
    height: 45px;
    width: 35px;
    margin-left: 20px;
    margin-top: -25px;
  }

  @media screen and (max-width: 480px) {
    height: 348px;
    width: 95%;

    .slider-text {
      font-size: 25px;
      margin-top: 3rem;
      line-height: 37px;
      margin-left: 18px;
      width: 250px;
    }

    .slider-text4 {
      font-size: 25px;
      line-height: 37px;
      margin-left: 18px;
      margin-top: 0px;
      margin-bottom: 2px;
      width: 250px;
    }

    .social-icon {
      height: 15px;
      text-align: center;
      width: 15px;
    }

    .social-text {
      font-size: 8px;
    }

    .slider1-img {
      margin-top: 100px;
      height: 250px;
      width: 100%;
      object-fit: contain;
    }

    .slider2-img {
      display: none;
    }

    .slider2-md-img {
      display: block;
      height: 100px;
      width: 160px;
    }

    .explore-btn {
      width: 125px;
      padding: 8px;
    }

    .slider3-img {
      display: none;
    }
    .coming-soon-img {
      display: none;
    }

    .slider3-md-img {
      display: block;
      width: 220px;
      height: 220px;
      object-fit: contain;
      position: absolute;
      right: 0;
    }

    .coming-soon {
      font-size: 10px;
      margin-left: 18px;
      margin-top: 8rem;
      margin-bottom: 0;
    }
    .slider-text3 {
      font-size: 25px;
      line-height: 27px;
      margin-left: 18px;
      margin-bottom: 0;
      width: 300px;
    }
    .sub-text {
      margin-left: 18px;
      font-size: 15px;
      width: 300px;
    }

    .coming-soon-md-img {
      display: block;
      position: absolute;
      top: 18px;
      left: -16px;
    }
    .slider4-img {
      display: none;
    }

    .slider4-md-img {
      display: block;
      width: 180px;
      object-fit: contain;
      margin-top: 20px;
    }
    .coming-soon4-md-img {
      position: absolute;
      top: 8px;
      left: 14px;
      display: block;
    }
    .social-icon2 {
      display: none;
    }

    .sub-text2 {
      width: 800px;
    }

    .feature-img-1 {
      margin-top: 0px;
    }
  }
}
