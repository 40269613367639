
@font-face {
    font-family: 'HP Simplified Lite';
    src:  url('../assets/fonts/WOFF2/HPSimplified_W_Lt.woff2') format('woff2'),
          url('../assets/fonts/WOFF/HPSimplified_W_Lt.woff') format('woff');
}

@font-face {
    font-family: 'HP Simplified Regular';
    src:  url('../assets/fonts/WOFF2/HPSimplified_W_Rg.woff2') format('woff2'),
          url('../assets/fonts/WOFF/HPSimplified_W_Rg.woff') format('woff');
}

@font-face {
    font-family: 'HP Simplified Bold';
    src:  url('../assets/fonts/WOFF2/HPSimplified_W_Bd.woff2') format('woff2'),
          url('../assets/fonts/WOFF/HPSimplified_W_Bd.woff') format('woff');
}