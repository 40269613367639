.timer-container {
  width: 170px;
  height: 45px;
  padding: 8px 8px;
  border: 0.5px solid #d8d8d8;
  border-radius: 11px;
  background-color: white;

  @media screen and (max-width: 823px) {
    right: 5%;
  }

  @media screen and (max-width: 690px) {
    right: 5%;
    top: 1rem;
    width: 120px;
    height: 36px;
    padding: 5px 2px;
    border-radius: 6px;
  }

  .stopwatch {
    height: 28px;
    width: 24px;
    margin-left: 8px;

    @media screen and (max-width: 690px) {
      height: 20px;
      width: 16px;
    }
  }

  .time-left {
    color: #0096d6;
    font-size: 15px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    margin-left: 11px;
    margin-top: 4px;

    @media screen and (max-width: 690px) {
      margin-left: 7px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
