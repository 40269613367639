@import "../../../scss/colors";

.business-planner-landing-page {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  height: 100%;

  .business-planner-lp-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .timer-container {
      width: 170px;
      height: 45px;
      padding: 8px 8px;
      border: 0.5px solid #d8d8d8;
      border-radius: 11px;
      position: absolute;
      right: 15%;
      background-color: white;

      @media screen and (max-width: 768px) {
        right: 5%;
      }

      @media screen and (max-width: 425px) {
        right: 5%;
        top: 1rem;
        width: 120px;
        height: 36px;
        padding: 5px 2px;
        border-radius: 6px;
      }

      .stopwatch {
        height: 28px;
        width: 24px;
        margin-left: 8px;

        @media screen and (max-width: 425px) {
          height: 20px;
          width: 16px;
        }
      }

      .time-left {
        color: #0096d6;
        font-size: 15px;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        margin-left: 11px;
        margin-top: 4px;

        @media screen and (max-width: 425px) {
          margin-left: 7px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .content {
      width: 100%;

      .item {
        min-width: 50%;
        min-height: 600px;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 575px) {
          width: 100%;
        }

        .item-img {
          width: 50%;
          background-color: #0096d6;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 575px) {
            width: 100%;
            order: 2;
          }

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .item-video {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 575px) {
            width: 100%;
            order: 2;
          }

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .item-content {
          justify-content: center;
          padding: 100px;
          width: 50%;
          margin: auto;

          @media screen and (max-width: 575px) {
            width: 100%;
            order: 1;
            padding: 25px;
          }

          @media screen and (max-width: 1080px) {
            padding: 25px;
          }

          .icon {
          }

          .content-title {
            font-size: 40px;
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

            @media screen and (max-width: 575px) {
              font-size: 32px;
            }
          }

          .content-description {
            font-size: 18px;
          }

          .time-info {
            margin-bottom: 20px;

            .stopwatch {
              width: 15px;
            }

            .time-need {
              font-size: 11px;
              margin-left: 10px;
              color: $primary-color;
              font-weight: 500;
            }
          }

          .redirection {
            text-decoration: none;
            color: $primary-color;
            font-size: 32px;

            .right-arrow {
              width: 30px;
              background-color: #ffffff;
              border: 1px solid $primary-color;
              padding: 10px;
              border-radius: 50%;
              margin-left: 10px;

              path {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.video-desktop {
  @media screen and (max-width: 1280px) {
    display: none !important;
  }
}

.video-mobile {
  @media screen and (min-width: 576px) {
    display: none !important;
  }
}

.video-tab {
  @media screen and (max-width: 575px) {
    display: none !important;
  }
  @media screen and (min-width: 1280px) {
    display: none !important;
  }
}
