@import "./../../../scss/colors";

.planner-shadow {
  box-shadow: 0px 0px 40px #00000019;
}
.bp-reach-budget-recommendation-collapsed-content {
  padding: 47px 53px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d8d8d8;
  opacity: 1;
  border-radius: 12px;

  @media screen and (max-width: 672px) {
    width: 100%;
  }

  .bp-reach-budget-recommendation-collapsed-content-heading {
    font-size: 18px;
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
  }

  .bp-reach-budget-recommendation-collapsed-content-info {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 672px) {
      flex-direction: column;
    }

    .bp-reach-budget-recommendation-collapsed-content-info-left {
      padding-right: 12px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 672px) {
        width: 100%;
      }

      .bp-reach-budget-recommendation-collapsed-content-info-left-item {
        @media screen and (max-width: 672px) {
          margin-bottom: 30px;
        }

        p {
          font-size: 14px;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          margin-bottom: 0px;
        }

        .hp-slider {
          .hp-slider-container {
            margin-bottom: 0px !important;

            .slider-label {
              color: $primary-color;
            }
          }
        }
      }
    }

    .bp-reach-budget-recommendation-collapsed-content-info-right {
      padding-left: 12px;
      width: 50%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 672px) {
        width: 100%;
      }

      .bp-reach-budget-recommendation-collapsed-content-info-right-item {
        .bp-reach-budget-recommendation-collapsed-content-info-right-item-title {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 20px;
          width: 130px;
        }

        .bp-reach-budget-recommendation-collapsed-content-info-right-item-value {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 26px;
          color: $primary-color;
        }

        .bp-reach-budget-recommendation-collapsed-content-info-right-item-description {
          font-size: 20px;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        }

        .bp-reach-budget-recommendation-collapsed-content-info-right-item-description-alt {
          font-size: 32px;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        }

        .show-planner-btn {
          display: flex;
          align-items: center;
          height: 44px;
          font-size: 18px;
          background: #0096d6 0% 0% no-repeat padding-box !important;
          border-radius: 22px;
          opacity: 1;
          text-transform: none;
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          margin: auto;
        }
      }
    }
  }

  .tooltip-icon {
    position: relative;
    display: inline-block;

    .tooltip-text {
      visibility: hidden;
      width: 500px;
      text-align: left;
      background-color: #fff;
      color: #000;
      border-radius: 6px;
      padding: 8px 15px;
      position: absolute;
      z-index: 1;
      top: -6rem;
      left: 170%;
      box-shadow: 0px 0px 15px #00000025;
      font-size: 10px;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      line-height: 1.3;
    }

    .tooltip-text-cpc {
      top: -4rem !important;
    }

    .tooltip-text-cr {
      top: -4.4rem !important;
    }

    .tooltip-text-asv {
      top: -3.5rem !important;
    }

    .tooltip-text::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
    }
  }

  .tooltip-icon:hover {
    .tooltip-text {
      visibility: visible;
      font-size: 10px;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    }
  }
}

.info-icon {
  color: #0096d6;
  padding-left: 5px;
}
