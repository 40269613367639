.grid-wrapper {
  cursor: pointer;
  .grid-card {
    .grid-wrap-container {
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .grid-wrap {
        background: #0096d6;
        padding: 10px;
        margin: 0 15px;
        border-radius: 10px;
        height: 60px;

        img {
          width: 90px;
          height: 60px;
          position: absolute;
          top: 70px;
        }
      }
    }

    &:hover {
      .grid-wrap-container {
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
      }
      .grid-wrap {
        height: 65px;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;

        img {
          width: 95px;
          height: 65px;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}
