.modal-backdrop {
  background-color: transparent !important;
}
.filter {
  filter: blur(5px);
}
.edc-container {
  .edc-wrapper {
    padding: 25px 150px;
    @media screen and (max-width: 576px) {
      padding: 15px 20px;
    }
    border-bottom: 1px solid #f8f8f8;

    .search-result {
      font-size: 32px;
      color: #000000;
    }
    .country {
      font-size: 18px;
      margin: 0;
    }

    .edc-name {
      color: #0096d6;
      font-size: 22px;
      margin: 0;
    }
    .edc-desc {
      font-size: 18px;
      margin: 0;
    }
    .edc-country {
      font-size: 14px;
    }
    .edc-contact {
      font-size: 16px;
      margin: 0;
    }
    .edc-contact-name {
      font-size: 18px;
      margin: 0;
    }

    .edc-button {
      cursor: pointer;
      .info {
        border: 0.5px solid #fff;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7.5px;
        font-size: 12px;
      }
      p {
        margin-bottom: 0;
        color: #fff;
      }
      background: #0096d6;
      box-shadow: 0px 0px 14px #0000005c;
      margin: 10px 0;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 25px;
    }

    .checkbox-wrap {
      label {
        color: #0096d6;
      }
    }

    .edc-services-wrap {
      padding-top: 1rem;

      .edc-service-text-blue {
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 16px;
      }

      .services-list {
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 14px;
      }
      .services-item {
      }
    }
  }
}
