.assessment-start-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .assessment-start-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .welcome-text {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 32px;
      text-align: center;

      @media screen and (max-width: 575px) {
        font-size: 22px;
      }
    }

    .welcome-msg {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 32px;
      margin-top: 1rem;

      @media screen and (max-width: 575px) {
        font-size: 22px;
      }
    }

    .assessment-start-btn-wrap {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 21px;

      @media screen and (max-width: 575px) {
        font-size: 21px;
      }
    }

    .assessment-start-link-wrap {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 22px;
      margin-top: 1rem;

      @media screen and (max-width: 575px) {
        font-size: 22px;
      }

      a {
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-decoration: underline;
        margin-top: 1rem;
        cursor: pointer;
      }
    }
  }
}
