.floating {
  position: absolute;
  right: 23%;
  top: 25%;

  @media only screen and (min-device-width: 1680px) and (max-device-width: 1800px) {
    right: 27%;
    top: 24%;
  }

  .button {
    background-color: #0096d6;
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    cursor: pointer;
    height: 30px;
  }

  .line {
    height: 500px;
    background-color: #0096d6;
    width: 5px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.floatingActive {
  position: absolute;
  right: 23%;
  top: 25%;
  z-index: 999999999999;
  @media only screen and (min-device-width: 1680px) and (max-device-width: 1800px) {
    right: 27%;
    top: 24%;
  }

  .button {
    color: #0096d6;
    padding: 5px 10px;
    font-size: 14px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    cursor: pointer;
    height: 30px;
    margin-top: 20px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .line {
    width: 200px;
    margin-right: 2px;
    border-radius: 2px;
    cursor: pointer;
  }

  .search {
    border-radius: 10px;
    background-color: #f0f0f0;
    color: #919191;
    border: 0;
    width: 75%;
    padding: 2px 10px;
    font-size: 13px;
  }

  .question {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-bottom: 0;
  }
  .answer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #0096d6;
    background-color: #d5f2ff;
    padding: 5px;
  }
  .option {
    color: #fff;
    background-color: #0096d6;
    padding: 0 5px;
    border-radius: 3px;
    margin-right: 5px;
    font-size: 9px;
  }
  .sol {
    margin-bottom: 0;
    font-size: 9px;
  }
}

.solWrapper {
  max-height: 500px;
  overflow: scroll;
  padding-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
}
