.edc-container {
  .edc-bg-wrap {
    height: 193.5px;
    width: 100%;
    object-fit: cover;
  }

  .edc-filter-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    flex-direction: column;

    .edc-heading-wrap {
      width: 60%;
      @media screen and (max-width: 576px) {
        width: 80%;
      }
    }

    .edc-title {
      margin: 0 !important;
    }

    .edc-heading {
      font-size: 32px;
      white-space: nowrap;
      margin: 0;
      margin-bottom: 1rem;
      @media screen and (max-width: 576px) {
        white-space: normal;
      }
    }

    .edc-filter-contain {
      width: 60%;
      @media screen and (max-width: 576px) {
        width: 80%;
      }
      .title {
        font-size: 18px;
        margin-bottom: 0;
      }

      .heading {
        font-size: 32px;
        white-space: nowrap;
        margin: 0;
        @media screen and (max-width: 576px) {
          white-space: normal;
        }
      }

      .desc {
        font-size: 18px;
      }

      .toggle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0px 0px 11px #003d571c;
      }
      .btn-submit {
        border-radius: 22px;
        background-color: #0096d6;
        border: 0;
        width: 100%;
        color: #fff;
        font-size: 18px;
        margin: 14px 0;
        padding: 2px 0;

        &:hover,
        &:focus,
        &:active {
          border: 0;
          outline: none;
        }
      }

      .input-cb {
        border-top: 0.5px solid #d3d3d3;
        border-left: 0.5px solid #d3d3d3;
        border-right: 0.5px solid #d3d3d3;
        padding: 0px 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        label {
          margin: 0;
          padding-left: 10px;
        }
      }

      .input-last {
        border-bottom: 0.5px solid #d3d3d3;
      }

      .rotate {
        transform: rotate(90deg);
      }

      .disclaimer {
        color: #707070;
        font-size: 14px;
      }
    }
  }
}
