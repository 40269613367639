.hp-pagespeed-header {
  display: flex;
  background-color: #0096d6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;

  .hp-pagespeed-header-title {
    color: #fff;
    display: flex;

    @media screen and (min-width: 576px) {
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
    }
  }

  .hp-pagespeed-header-url {
    background-color: #fff;
    color: #000;
    display: flex;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 11px #003d571c;
    border-radius: 6px;

    width: 300px;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 14px;

    @media screen and (min-width: 576px) {
      width: 500px;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
    }
  }
}
.hp-pagespeed-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0096d6;
  color: #fff;
  justify-content: center;
  align-items: center;

  .tab-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-transform: uppercase;

    @media screen and (min-width: 576px) {
      width: 500px;
    }
  }
  .hp-pagespeed-tab {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
  }

  .tab-selected {
    border-bottom: 5px solid #fff;
  }
}

.hp-pagespeed {
  display: flex;
  flex-direction: column;
  align-items: center;
  .hp-pagespeed-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;

    .hp-pagespeed-chart-graph {
    }

    .hp-pagespeed-chart-title {
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 22px;
      margin-top: 0.5rem;
    }

    .hp-pagespeed-chart-legend {
      border: 1px solid #e3e3e3;
      border-radius: 21px;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 1rem;
      margin-top: 0.5rem;
    }
  }
}

.hp-pagespeed-oppertunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 4rem 0 4rem;
  @media screen and (max-width: 575px) {
    padding: 2rem 0 0 0;
  }
  .oppertunity-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    .oppertunity-header-left {
      display: flex;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-transform: uppercase;

      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }
    .oppertunity-header-right {
      display: flex;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }
  }

  .oppertunity-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    height: 50px;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 14px;

    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
    .oppertunity-data-left {
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
        color: #ff4e42;
      }
    }
    .oppertunity-data-right {
      display: flex;
      color: #ff4e42;
      align-items: center;

      .score-chart {
        display: flex;
        flex-basis: 80%;
        height: 6px;
        width: 150px;
        background-color: #fff;
        position: relative;

        .chart-filling {
          position: absolute;
          width: 50%;
          background-color: #ff4e42;
          height: 6px;
          right: 0;
        }
      }

      .score-text {
        margin-left: 8px;
        display: flex;
        flex-basis: 20%;
      }
    }
  }
}

.red-bar {
  background-color: #ff4f42;
  height: 10px;
  width: 15px;
  margin-right: 5px;
  border-radius: 3px;
}

.yellow-bar {
  background-color: #ffa400;
  height: 10px;
  width: 15px;
  margin-right: 5px;
  margin-left: 15px;
  border-radius: 3px;
}

.green-bar {
  background-color: #0ace69;
  height: 10px;
  width: 15px;
  margin-right: 5px;
  margin-left: 15px;
  border-radius: 3px;
}

.legend-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hp-pagespeed-see-report {
  display: flex;
  align-items: center;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 575px) {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  cursor: pointer;

  a {
    color: #000;
    margin-right: 5px;
    display: flex;
    align-items: center;

    i {
      margin-left: 5px;
    }
    &:hover {
      color: #000;
    }
  }

  i {
    color: #0096d6;
  }
}
