@import "../../../scss/colors";
//Navbar style , TODO: move to another css file
.hp-nav {
  .hp-bp-navbar {
    padding: 1rem 3rem;

    .hp-logo-left {
      display: flex;
      flex-basis: 50%;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      @media (max-width: 425px) {
        flex-basis: 75%;
      }

      .hp-logo-text {
        font-size: 22px;
        margin-left: 10px;
        color: $primary-color;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      }
    }

    .hp-logo-right {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      text-align: right;
      justify-content: flex-end;

      .nav-links {
        display: flex;
        align-items: center;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: $primary-color;
        margin: 0 15px;

        a {
          color: #0096d6 !important;

          &:hover {
            color: #0096d6 !important;
          }
        }

        .fa-arrow-right {
          font-size: 0.85rem !important;
        }

        .icon-wrap {
          margin-left: 5px;
        }

        .icon-wrap-hamburger {
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .nav-hamburger {
        position: relative;
      }

      span {
        cursor: pointer;
      }
    }

    .hp-logo {
      width: 28.53px;
      height: 28.53px;
    }
  }

  .hp-nav-list {
    position: absolute;
    top: 0;
    width: 300px;
    text-align: left;
    right: 0px;
    background-color: #fff;
    z-index: 999;
    flex-direction: column;
    margin-top: -16px;
    margin-right: -16px;
    box-shadow: -4px 0px 35px #002a3c21;
    padding: 0 2rem;
    height: 100%;

    @media screen and (min-width: 576px) {
      min-height: 960px;
    }

    @media screen and (max-width: 575px) {
      min-height: 960px;
    }

    .hp-nav-listitem {
      padding: 1.5rem 0;
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      flex-direction: row;
      align-items: center;

      .hp-nav-listitem-left {
        display: flex;
        flex-basis: 80%;
        color: #0096d6;

        a {
          color: #0096d6 !important;

          &:hover {
            color: #0096d6 !important;
          }
        }
      }

      .hp-nav-listitem-right {
        display: flex;
        flex-basis: 20%;
        justify-content: flex-end;
      }

      .hp-menu-desc {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #000;
        margin-top: 0.6rem;
      }
    }

    .hp-nav-close-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      cursor: pointer;
    }

    .hp-menu-with-desc {
      flex-direction: column;
    }
  }
}
