html,
body,
.pdf-report-header,
.exec-summary-wrap,
.pdf-rec-speed-report-wrap {
  float: none;
}
@page {
  //size: A4;
  //margin-top: 2rem;
  //margin-bottom: 2rem;

  counter-increment: page;
  counter-reset: page 1;

  @bottom-left {
    content: "Page " counter(page) " of " counter(pages);
  }
}

@media print {
  h1,
  h2,
  h3,
  h4 {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  h1,
  h2,
  h3,
  h4 {
    min-height: 40px;
  }

  ul {
    display: block;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .hp-home {
    display: block;

    .hp-content-wrap {
      display: block;

      .executive-sumamry {
        display: block;
      }
    }

    .exec-assessment-summary {
      display: block;
    }
  }
  .executive-sumamry {
    display: block;
  }

  .pdf-report-header {
    display: block;
  }

  .hp-report-top {
    display: block;
  }

  .service-report-body {
    display: block;
    page-break-before: always;
  }

  .pdf-report-body {
    display: block;

    .pdf-report-section-header {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .hp-report-survey-chart {
      display: block;
    }

    .pdf-report-see-details-text {
      display: block;
    }

    .pdf-section-container {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;

      .pdf-section-header {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;

        .pdf-section-header-icon {
          display: block;
          page-break-inside: avoid;
          break-inside: avoid;
          min-height: 180px;
          height: 188px;
        }

        .pdf-section-header-text {
          display: block;
          page-break-inside: avoid;
          break-inside: avoid;
        }
      }

      .pdf-section-desc {
        display: block;
      }
    }
  }

  .exec-summary-wrap {
    display: block;
    page-break-after: always;
  }
  .pdf-report-section-recommenation {
    display: block;

    .pdf-recommenation-wrap {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .pdf-rec-item {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;

      .hp-recommend-item-title {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }

      .hp-recommend-item-score {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }

      .hp-assessment-scores-section-bar-wrap {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }

      .hp-report-score {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }

      .hp-assessment-scores-section-bar {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }

      .hp-assessment-scores-section-bar-fill {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }

    .rdf-rec-item-summary {
      display: block;
    }

    .pdf-rec-speed-report {
      display: block;

      .pdf-rec-speed-report-title {
        display: block;
      }

      .pdf-rec-speed-report-desc {
        display: block;
      }

      .pdf-rec-speed-report-container {
        display: block;
      }

      .pdf-rec-speed-report-wrap {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
  }

  .hp-report-top {
    display: block;
    width: 100%;
    .hp-report-top-left-panel {
      display: inline-block;
      width: 40%;
    }

    .hp-report-top-right-panel {
      display: inline-block;
      width: 60%;
    }
  }

  .pdf-report-guage-wrap {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid;

    .hp-report-guage-chart-wrap {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .hp-report-guage-title-score {
      display: block;
      page-break-inside: avoid;
      break-inside: avoid;
      min-height: 30px;
      margin-bottom: 0.5rem;
    }
  }

  .hp-rec-content-para1 {
    display: block;
  }

  .pdf-recommendation-title {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid;
    min-height: 32px;
    margin-bottom: 1rem;
  }

  .score-bar {
    display: block;
    .score-bar {
      display: block;
    }
    .score-bar-fill {
      display: block;
    }
  }

  .peer-rating-disclaimer {
    display: block;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 12px;
    width: 100%;

    .label-wrap {
      width: 50%;

      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }

    .disclaimer-label {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    }
  }
}

.hp-pdf-report-page {
  display: flex;
  flex-direction: column;

  .report-radar-wrap {
  }

  .digital-spider-chart {
  }

  .pdf-report-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #cccccc;
    align-items: center;
    padding-bottom: 1rem;
    background-color: #0096d6;
    margin-top: 1rem;
    .header-img {
      display: flex;
      align-items: center;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
      z-index: 999;
    }

    .header-text {
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 40px;
      color: #fff;
    }
  }

  .pdf-top-panel-icon {
    align-items: flex-start;
  }

  .hp-report-top {
    margin-top: 2rem;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    .hp-report-top-left-panel {
      @media screen and (max-width: 575px) {
        border-bottom: 1px solid #efefef;
        padding-bottom: 1rem;
      }

      @media screen and (min-width: 576px) {
        flex-basis: 40%;
      }

      .hp-report-page-link1 {
        text-transform: uppercase;
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.95px;
      }

      .hp-report-page-link2 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 29px;
      }

      .hp-report-page-link3 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #cccccc;
        line-height: 18px;
      }
    }

    .hp-report-top-right-panel {
      @media screen and (max-width: 575px) {
        margin-top: 1rem;
      }

      @media screen and (min-width: 576px) {
        flex-basis: 60%;
      }

      .hp-report-heading {
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 22px;
        line-height: 26px;

        @media screen and (max-width: 575px) {
          font-size: 18px;
          margin-bottom: 0.5rem;
        }
      }

      .hp-report-content {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 29px;
      }
    }
  }

  .pdf-report-body {
    display: flex;
    margin-top: 1.5rem;
    flex-direction: column;
    .pdf-report-section-header {
      display: flex;
      background: transparent
        linear-gradient(98deg, #aae6ff 0%, #a2e2fd 18%, #0096d6 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: -7px 7px 10px #f4f4f4;
      border-radius: 29px;
      height: 136px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .pdf-header-level1 {
        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
      }

      .pdf-header-level2 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 22px;
      }

      .pdf-header-level3 {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 12px;
        color: #fff;
      }
    }

    .pdf-report-see-details-text {
      display: flex;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 18px;
      margin-top: 1.5rem;
    }

    .pdf-section-container {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .pdf-section-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 1rem;

        .pdf-section-header-icon {
          img {
            width: 156px;
            height: 150px;
          }
        }
        .pdf-section-header-text {
          text-transform: uppercase;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 20px;
          margin-top: 0.5rem;
        }
      }

      .pdf-section-desc {
        display: flex;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 18px;
        justify-content: center;
        margin-top: 1rem;
      }

      .pdf-report-guage-wrap {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;

        .hp-report-guage-chart-wrap {
          .hp-report-guage-title {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 20px;
            display: flex;
            justify-content: center;
          }
          .hp-report-guage-title-score {
            font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
            font-size: 18px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .pdf-report-section-recommenation {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .pdf-recommendation-title {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 32px;
          display: flex;
          justify-content: center;
        }

        .pdf-recommenation-wrap {
          .pdf-rec-item {
            .rdf-rec-item-summary {
            }
          }

          .pdf-rec-item-details {
            margin-top: 1.5rem;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 11px;
            flex-direction: column;
            display: flex;

            @media print {
              box-shadow: 0px 3px 6px #00000029;
              -moz-box-shadow: 0px 3px 6px #00000029;
              -webkit-box-shadow: 0px 3px 6px #00000029;
              border: 1px solid #00000029;
              -webkit-print-color-adjust: exact;
              -webkit-filter: blur(0);
            }

            .hp-rec-content-title {
              font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
              font-size: 18px;
              background-color: #a8e6ff;
              height: 44px;
              padding: 0.5rem 1rem;
              border-radius: 11px 11px 0 0;
            }

            .hp-rec-content-para1 {
              font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
              font-size: 18px;
              padding: 1rem;
              padding-top: 2rem;
              flex-direction: column;

              h1 {
                font-family: "HP Simplified Regular", Arial, Helvetica,
                  sans-serif;
                font-size: 2.5rem;
              }

              h2 {
                font-family: "HP Simplified Regular", Arial, Helvetica,
                  sans-serif;
                font-size: 2rem;
              }

              h3,
              h4,
              h5,
              h6 {
                font-family: "HP Simplified Regular", Arial, Helvetica,
                  sans-serif;
              }

              p {
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                font-size: 18px;

                strong {
                  font-family: "HP Simplified Regular", Arial, Helvetica,
                    sans-serif;
                }
              }

              ul {
                li {
                  font-family: "HP Simplified Lite", Arial, Helvetica,
                    sans-serif;
                  font-size: 18px;

                  strong {
                    font-family: "HP Simplified Regular", Arial, Helvetica,
                      sans-serif;
                  }
                }

                strong {
                  font-family: "HP Simplified Regular", Arial, Helvetica,
                    sans-serif;
                }
              }
            }
          }
        }
      }
    }
  }

  .hp-report-partner-position {
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    font-size: 18px;
    letter-spacing: 0.95px;
    line-height: 21px;
    text-transform: uppercase;
    margin-left: 5px;
  }
}

.rdf-rec-item-summary .hp-report-recommendation-item {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;

  @media print {
    box-shadow: 0px 3px 6px #00000029;
    -moz-box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #00000029;
    -webkit-print-color-adjust: exact;
    -webkit-filter: blur(0);
  }

  .hp-recommend-item-title {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
    @media screen and (max-width:575px) {
      font-size: 14px;
      margin-top: 0.5rem;
    }
  }

  .hp-recommend-item-score {
    .hp-assessment-scores-section-bar-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 0.5rem;
    }

    .hp-assessment-scores-section-bar {
      display: flex;
      width: 100%;
      height: 7px;
      background: #f4f4f4 0% 0% no-repeat padding-box;
      position: relative;

      .hp-assessment-scores-section-bar-fill {
        display: flex;
        position: absolute;
        height: 7px;
        width: 70%;
        background: transparent linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%)
          0% 0% no-repeat padding-box;
      }
    }

    .hp-report-score {
      display: flex;
      flex-direction: row;
      font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
      font-size: 12px;

      .hp-report-score-left {
        display: flex;
        flex-basis: 50%;
        color: #707070;
      }

      .hp-report-score-right {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;
        font-size: 18px;
      }
    }

    @media screen and (max-width:575px) {
      font-size: 18px;
    }
  }

  .hp-recommend-item-content {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin-top: 1rem;
    @media screen and (max-width:575px) {
      font-size: 18px;
      margin-top: 0.5rem;
    }
  }

  .hp-recommend-item-link {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    margin-top: 1.5rem;
    color: #0096d6;
    font-size: 24px;
    display: flex;
    flex-direction: row;

    .hp-recommend-item-link-icon {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #0096d6;
      margin-left: 0.5rem;
      text-decoration: none !important;
    }

    .hp-recommend-item-link-text {
      display: flex;
      text-decoration: underline;
      cursor: pointer;

      a {
        color: #0096d6;

        &:hover {
          color: #0096d6;
        }
      }
    }

    @media screen and (max-width:575px) {
      font-size: 18px;
      margin-top: 1rem;
    }
  }
}

.pdf-rec-speed-report {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pdf-rec-speed-report-container {
    width: 100%;
  }
  .pdf-rec-speed-report-title {
    display: flex;
    text-transform: uppercase;
    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  .pdf-rec-speed-report-desc {
    margin-top: 1.5rem;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    display: flex;
    flex-direction: column;

    .para1 {
    }

    .para2 {
      margin-top: 1rem;
    }

    .para3 {
      margin-top: 1rem;
    }
  }

  .pdf-rec-speed-report-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pdf-rec-speed-reportwrap-head {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      color: #0096d6;
      font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
      font-size: 29px;

      i {
        margin-right: 8px;
      }
    }
  }
}

.executive-sumamry {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  .exec-summary-title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 1rem;
    .title-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-basis: 50%;
      .title-logo {
        display: flex;
        width: 56px;
        height: 56px;
        img {
          width: 56px;
          height: 56px;
        }
      }

      .title-text {
        margin-left: 10px;
        color: #0096d6;
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 20px;
      }
    }

    .title-right {
      display: flex;
      flex-direction: row;
      flex-basis: 50%;

      .title-img {
      }

      .title-content-wrap {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .title-1 {
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
          line-height: 21px;
        }

        .title-2 {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 18px;
          line-height: 29px;
        }

        .title-3 {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 14px;
          color: #cccccc;
          line-height: 14px;
        }
      }
    }
  }

  .exec-assessment-summary {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 1rem;
    .assessment-info {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;

      .assessment-name {
        width: 184px;
        height: 99px;
        box-shadow: -7px 7px 10px #f4f4f4;
        border-radius: 29px;
        background: transparent
          linear-gradient(110deg, #aae6ff 0%, #a2e2fd 18%, #0096d6 100%) 0% 0%
          no-repeat padding-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .line-1 {
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 12px;
          text-transform: uppercase;
        }

        .line-2 {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 15px;
        }
      }

      .assessment-welcome-msg {
        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
        font-size: 22px;
        color: #0096d6;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .assessment-welcome-desc {
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        font-size: 15px;
      }
    }

    .assessment-chart {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      justify-content: center;

      .chartjs-size-monitor {
        position: relative;
      }
    }
  }

  .exec-section-sumamry {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    flex: 100%;
    .section-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 60%;
      .info-left {
        display: flex;

        .section-img {
        }
      }

      .info-right {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        .section-name {
          text-transform: uppercase;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 20px;
        }

        .section-desc {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 15px;
        }
      }
    }

    .section-chart {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex: 40%;
      .section-chart-item-left {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .gauge-chart1 {
          width: 98px;
        }

        .score-text {
          display: flex;
          justify-content: flex-end;
        }

        .score-val {
          display: flex;
          justify-content: flex-end;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
        }
      }

      .section-chart-item-right {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        align-items: center;

        .gauge-chart1 {
          width: 98px;
        }

        .score-val {
          display: flex;
          justify-content: flex-end;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
        }
      }

      .section-chart-item.item-left {
        justify-content: flex-end;
      }
    }
  }

  .exec-summary-wrap {
    display: block;
  }

  .exec-subsection-summary {
    display: flex;

    .exec-subsection-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .exec-subsection-item {
        display: flex;
        flex-direction: column;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        height: 235px;
        padding: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        flex: 0 0 48%;
        max-width: 48%;

        @media print {
          border: 1px solid #00000029;
          height: 260px;
        }

        .subsection-name {
          font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          font-size: 15px;
          color: #0096d6;
        }

        .subsection-score {
          .score-text {
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            font-size: 15px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }

          .score-bg {
            display: flex;
            position: relative;
            height: 6px;
            width: 100%;
            background-color: #f4f4f4;
            margin-top: 0.5rem;
            margin-bottom: 0.8rem;

            .score-fg {
              position: absolute;
              height: 6px;
              background: transparent
                linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%) 0% 0% no-repeat
                padding-box;
            }
          }
        }

        .subsection-desc {
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
          font-size: 15px;
        }
      }
    }
  }
}

.pdf-end {
  display: none;
  @media print {
    display: flex;
    justify-content: center;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #000;
  }
}

.score-bar {
  .report-score-dflex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .title-head-text-left {
    padding-top: 20px;
    font-size: 14px;
    color: #000000;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    margin-bottom: 0;
  }

  .title-head-text-right {
    padding-top: 20px;
    font-size: 18px;
    color: #000000;
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
    margin-bottom: 0;
  }

  .score-bar {
    display: flex;
    width: 100%;
    height: 7px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    position: relative;
  }
  .score-bar-fill {
    display: flex;
    position: absolute;
    height: 7px;
    width: 70%;
    background: transparent linear-gradient(90deg, #ddf5ff 0%, #00b3ff 100%) 0%
      0% no-repeat padding-box;
  }
}

.peer-rating-disclaimer {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 12px;
  width: 100%;

  .label-wrap {
    width: 50%;

    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  .disclaimer-label {
    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  }
}

.hp-blue-header {
  background-color: #0096d6;
  color: #fff;
  padding: 4%;
  display: flex;
  align-items: center;

  img {
    height: 30px;
    width: 30px;
  }

  p {
    margin-bottom: 0;
    margin-left: 20px;
    font-size: 25px;
  }
}

.wrap-grid {
  background: transparent
    linear-gradient(98deg, #aae6ff 0%, #a2e2fd 18%, #0096d6 100%) 0% 0%
    no-repeat padding-box;
  margin: 5%;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5%;
  p {
    margin-bottom: 0;
  }
}

.heading {
  margin: 0 5%;
  color: #0096d6;
}

.gridCorner {
  border-radius: 20px;
  background-color: #9adcf8;
  padding: 5%;
  margin-top: 5%;
}

.subWrap {
  box-shadow: 0px 3px 10px #00000021;
  border-radius: 16px;
  margin: 20px 0;
  padding: 5%;

  .subSec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      height: 85px;
      width: 85px;
    }
  }

  .partitioner {
    height: 300px;
    width: 0.25px;
    border: 0.25px dotted rgb(100, 100, 100);
  }

  .recommdationsWrap {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 8%;

    .rec-header {
      background-color: #a8e6ff;
      padding: 2% 3%;
      p {
        margin-bottom: 0;
      }
    }
  }

  .score-wrapper {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 2%;
    margin-bottom: 3%;
  }
}

.subSecWrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px #00000029;
  border-radius: 33px;
  padding: 5%;
  margin-bottom: 6%;
  img{
      height: 80px;
  }
  .partitioner {
    height: 300px;
    width: 0.25px;
    border: 0.25px dotted rgb(100, 100, 100);
  }
}
