.faq-container {
  padding: 0 1rem !important;
  @media screen and (min-width: 576px) {
    padding: 0 8rem !important;
  }

  .heading {
    font-size: 22px;
    color: #000000;
  }

  .accordion-collapse {
    border-bottom: 0.5px solid #e3e3e3;
    .toggle-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      p {
        color: #000000;
        font-size: 18px;
        text-align: left;
      }
      .icon {
        color: #0096d6;
        margin-top: 5px;
        height: 21px;
        width: 21px;
      }
    }
    .toggle-body {
      p {
        text-align: left;
        font-size: 14px;
        color: #000000;
      }
    }
  }

  .back-btn {
    color: #0096d6;
    font-size: 18px;
    text-decoration: underline;
    text-underline-offset: 2px;
    margin-top: 8px;
    cursor: pointer;
  }
}
.help-retake-wrap {
  border-bottom: 0.5px solid #e3e3e3;
  padding-bottom: 1rem;
  .help-retake-title {
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 32px;
  }
  .help-retake-desc {
    padding-top: 0.7rem;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  .help-retake-options {
    padding-top: 1rem;
    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
    font-size: 18px;
    .help-options {
    }
  }

  .help-retake-cta {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    .send-request {
      border: none !important;
      appearance: none;
      -webkit-appearance: none;
      margin-top: 0px !important;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;
    }

    .download-pdf {
      color: #0096d6;
      border: none;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
      font-size: 18px;

      &:hover {
        color: #0096d6;
      }
    }
  }
}

.faq-bread-crumb {
  padding: 0 1rem;
}

.help-retake-label {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.help-textarea {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 0.7rem;
  border: 1px solid #e3e3e3;
}

.hp-faq-title {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 32px;
}

.faq-help-wrap {
  .modal-dialog {
    max-width: 400px !important;
  }
  .hp-help-cta {
    border: none;
    background: #0096d6 0% 0% no-repeat padding-box;
    border-radius: 22px;
    color: #fff;
    padding: 0.5rem 1.5rem;
  }
}
.hp-retake-confirm-msg {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  font-size: 18px;
}
