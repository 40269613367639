@import "../../../scss/colors";

.business-planner-summary-page {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

  .container {
    margin: auto;
    width: 768px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .bp-smry-investment {
      border-radius: 11px 11px 0px 0px;
      margin-top: 30px;
      background-color: $primary-color;
      padding: 67px 99px 67px 99px;

      @media screen and (max-width: 768px) {
        padding: 40px 20px;
      }

      .bp-smry-invst-icon {
        img {
          margin-bottom: 24px;
        }
      }

      .bp-smry-invst-ttls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .bp-smry-invst-ttl {
          text-align: left;
          text-transform: uppercase;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          font-size: 18px;
          color: #fff;
          width: 50%;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        .right {
          padding: 0 0 0 50px;

          @media screen and (max-width: 768px) {
            padding: 0 0 0 20px;
          }
        }
      }

      .bp-smry-invst-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .bp-smry-invst-data-left {
          width: 50%;
          .bp-smry-invst-data-items {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            color: #fff;

            .bp-smry-invst-data-item {
              font-size: 22px;
              margin-bottom: 0px;

              @media screen and (max-width: 768px) {
                font-size: 16px;
              }

              .bp-smry-invst-data-itm-ttl {
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                margin-bottom: 0px;
              }

              .bp-smry-invst-data-itm-desc {
                font-family: "HP Simplified Regular", Arial, Helvetica,
                  sans-serif;
              }
            }
          }
        }

        .bp-smry-invst-data-right {
          width: 50%;
          padding: 0 0 0 50px;

          @media screen and (max-width: 768px) {
            padding: 0 0 0 20px;
          }

          .bp-smry-invst-data-items {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            color: #fff;

            .bp-smry-invst-data-item {
              display: flex;
              flex-direction: row;
              font-size: 22px;
              margin-bottom: 10px;

              @media screen and (max-width: 768px) {
                font-size: 16px;
              }

              .bp-smry-invst-data-itm-ttl {
                margin-right: 30px;
                font-family: "HP Simplified Regular", Arial, Helvetica,
                  sans-serif;

                sup {
                  font-size: 12px;
                }
              }

              .bp-smry-invst-data-itm-desc {
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
              }
            }
          }
        }
      }
    }

    .bp-smry-breakeven {
      padding: 74px 99px 74px 99px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 40px #00000019;

      @media screen and (max-width: 768px) {
        padding: 40px 20px;
      }

      .bp-smry-brkevn-icon {
        display: flex;
        align-items: flex-end;
        color: $primary-color;
        margin-bottom: 20px;

        img {
          margin-right: 15px;
          font-size: 60px;
          color: $primary-color;
        }

        p {
          font-size: 18px;
          font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
          margin-bottom: 0;
        }
      }

      .bp-smry-brkevn-data {
        display: flex;
        flex-direction: column;

        .bp-smry-brkevn-dt-itm {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          font-size: 22px;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }

          &:first-child {
            border-top: 1px dotted #ccc;
            border-bottom: 1px dotted #ccc;
          }

          .bp-smry-brkevn-itm-key {
            margin: 10px 0px 10px 10px;

            &::before {
              content: "\00a0 ";
              padding: 0px 15px 0px 0px;
            }
          }

          .bp-smry-brkevn-itm-key-ico {
            margin: 10px 0px 10px 10px;
            display: flex;

            &::before {
              font-family: "Font Awesome 5 Free";
              content: "\f005";
              padding: 8px 10px 0px 0px;
              font-size: 10px;
              color: #0096d6;
              font-weight: 900;
            }
          }

          .bp-smry-brkevn-itm-value {
            margin: 10px 15px 10px 0px;
            text-align: right;
          }
        }

        .bgnd {
          background: #e3f7ff 0% 0% no-repeat padding-box;
          border-radius: 10px;
        }
      }
    }

    .bp-smry-download {
      background-color: $primary-color;
      border-radius: 0px 0px 11px 11px;
      opacity: 1;
      padding: 47px;

      @media screen and (max-width: 768px) {
        padding: 40px 20px;
      }

      .dwn-info {
        font-size: 32px;
        color: #ffffff;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }

      .dwn-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          .download-icon_wrap {
            width: 40px;
            height: 40px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
          }
          img {
            width: 44px;
            background-color: #ffffff;
            border-radius: 50%;
            padding: 9px;

            @media screen and (max-width: 768px) {
              width: 32px;
              padding: 5px;
            }
          }

          .download-text {
            padding-bottom: 0;
            margin-bottom: 0;
            margin-left: 7px;
            font-size: 16px;
            color: #ffffff;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
          }
        }
      }
    }

    .bp-smry-send {
      padding: 40px 40px;

      @media screen and (max-width: 768px) {
        padding: 40px 20px;
      }

      .snd-info {
        font-size: 60px;
        text-align: center;
        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        text-align: left;

        @media screen and (max-width: 768px) {
          font-size: 40px;
        }
      }

      .snd-btn {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;
          margin-right: 10px;
          color: $primary-color;
          font-size: 22px;
          font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
        }

        img {
          border: 1px solid $primary-color;
          border-radius: 50%;
          width: 28px;
          height: 28px;
          padding: 8px;
        }
      }
    }
  }
}

.font-regular {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
}

.font-lite {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
}

.p-clr {
  color: $primary-color;
}

.bp-reach-budget-recommendation-expanded-disclaimer {
  padding: 1rem 0;
  margin-bottom: 1rem;
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #cccccc;

  .bp-reach-budget-recommendation-expanded-disclaimer-title {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .bp-reach-budget-recommendation-expanded-disclaimer-description {
    font-size: 12px;
  }
}
