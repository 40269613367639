@import "./../../../scss/colors";

@page {
    //size: A4;
    margin-top: 1rem;
    //margin-bottom: 2rem;

    counter-increment: page;
    counter-reset: page 1;

    @bottom-left {
        content: "Page " counter(page) " of " counter(pages);
    }
}

.bp-pdf {
    .executive-summary {
        width: 100%;

        .section-1 {
            background-color: #0096d6;
            height: 240px;
            color: #fff;
            margin: 0 auto;

            .section-title {
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                font-size: 40px;
                text-align: center;
                height: 185px;
                padding-top: 4rem;
                width: 768px;
                margin: 0 auto;

                .hp-logo {
                    margin-right: 0.5rem;
                }
            }

            .section-info {
                display: block;
                text-align: center;
                width: 1024px;
                margin: 0 auto;

                .partner-name {
                    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                    font-size: 18px;
                    float: left;
                }

                .created-date {
                    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                    font-size: 12px;
                    float: right;
                }
            }
        }

        .section-2 {
            width: 1024px;
            margin: 1rem auto;
            line-height: 2.5;
            margin-top: 1rem;

            .summary-title {
                font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                font-size: 18px;
                color: #0096d6;
            }

            .summary-data {
                font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                font-size: 20px;

                .summary-label {
                    color: #000;
                }

                .summary-value {
                    color: #0096d6;
                }
            }
        }
    }

    .channel-selections {
        width: 1024px;
        margin: 2rem auto 0 auto;

        @media print {
            page-break-after: always;
        }

        .channel-item {
            width: 500px;
            background-color: #f0f8fc;
            padding: 2rem;
            display: inline-block;
            margin-right: 5px;
            margin-top: 5px;
            page-break-inside: avoid;
            break-inside: avoid;
            height: 360px;
            vertical-align: top;
        }

        .channel-title {
            color: #0096d6;
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            font-size: 18px;
        }

        .channel-sub-title {
            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            font-size: 16px;
        }

        .channel-description {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 12px;
        }

        .channel-budget {
            font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            margin-top: 0.5rem;
        }

        .channel-budget-features {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 12px;
            margin-top: 0.5rem;
            line-height: 2;
        }
    }

    .detailed-summary {
        width: 100%;
        margin-top: 1.5rem;
        height: 500px;
        page-break-inside: avoid;
        break-inside: avoid;

        .summary-left {
            color: #fff;
            height: 500px;
            float: left;
            width: 50%;

            .left-container {
                background-color: #0096d6;
                height: 500px;

                .left-img {
                }

                .left-summary-table {
                    padding: 8rem 6rem;
                    background-color: #0096d6;
                    display: block;

                    .summary-img {
                    }

                    .left-table-wrap {
                        background-color: #0096d6;
                        float: left;
                        height: 250px;

                        .bp-summary {
                            .summary-title {
                                font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                                font-size: 14px;
                                margin-top: 0.5rem;
                                margin-bottom: 1rem;
                            }

                            .summary-row {
                                margin-bottom: 1rem;

                                .summary-label {
                                    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                }

                                .summary-val {
                                    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .right-table-wrap {
                        background-color: #0096d6;
                        width: 50%;
                        height: 250px;
                        float: right;

                        .bp-investment {
                            .investment-title {
                                font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                                font-size: 14px;
                                margin-top: 0.5rem;
                                margin-bottom: 1rem;
                            }

                            .investment-data-row {
                                margin-bottom: 1rem;

                                .investment-quarter {
                                    display: inline-block;
                                    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    width: 30%;
                                }

                                .investment-value {
                                    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .summary-right {
            width: 50%;
            float: right;
            height: 500px;
            padding: 2rem 6rem;
            margin-bottom: 2rem;

            .roi-title {
                font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                font-size: 16px;
                color: #0096d6;
            }

            .main-channels-title {
                margin-top: 0.5rem;
                font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                font-size: 16px;
                border-top: 1px solid #c1c1c1;
                border-bottom: 1px solid #c1c1c1;
                padding: 0.5rem 0;
            }

            .main-channel-row {
                font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                font-size: 16px;
                padding: 0.5rem 0;

                .main-channel-label {
                    display: inline-block;
                    width: 70%;
                }

                .main-channel-value {
                    width: 30%;
                    display: inline-block;
                    text-align: right;
                }
            }

            .roi-tital-cost-row {
                font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                font-size: 16px;
                padding: 0.5rem 0;

                .roi-total-label {
                    width: 70%;
                    display: inline-block;
                }

                .roi-total-value {
                    width: 30%;
                    display: inline-block;
                    text-align: right;
                }
            }

            .roi-split-row {
                padding: 0.5rem 0;

                .roi-split-label {
                    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                    font-size: 12px;
                    display: inline-block;
                    width: 70%;
                }

                .roi-split-value {
                    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    color: #0096d6;
                    display: inline-block;
                    width: 30%;
                    text-align: right;
                }
            }
        }
    }

    .reach-plan {
        width: 1024px;
        margin: 2rem auto;
        border-top: 1px solid #cccccc;
        padding-top: 2rem;
        page-break-inside: avoid;
        break-inside: avoid;

        @media print {
            page-break-after: always;
        }

        .bp-reach-budget-recommendation-expanded-table {
            overflow-x: scroll;

            &::-webkit-scrollbar,
            &::-webkit-scrollbar-track {
                display: none;
            }

            table {
                width: 100%;

                .tb-header-row {
                    border-bottom: 1px solid #cccccc;
                    border-top: 1px solid #cccccc;

                    td {
                        text-align: left;
                        font-size: 14px;
                        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                        width: fit-content;
                        min-width: 60px;
                        padding: 8px 0px;

                        &:nth-child(1) {
                            text-align: center;
                            width: 250px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            background-color: #ffffff;
                            left: 0;
                            position: sticky;
                            z-index: 9999;

                            @media screen and (max-width: 575px) {
                                min-width: 150px;
                                width: 210px;
                            }

                            &::before {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: " ";
                                height: 100%;
                                top: 0;
                                left: -15px;
                                position: absolute;
                                width: 15px;
                            }

                            &::after {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: "";
                                height: 100%;
                                position: absolute;
                                top: 0;
                                right: -15px;
                                width: 15px;
                            }
                        }

                        .md-form {
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                    }
                }

                .tb-row-disabled {
                    color: #cccccc;

                    td {
                        color: #cccccc;

                        input {
                            color: #cccccc !important;
                            border-bottom: 1.5px solid #cccccc !important;
                        }
                    }
                }

                .tb-data-row {
                    height: 50px;

                    td {
                        font-size: 14px;
                        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                        padding: 0px;

                        @media screen and (max-width: 575px) {
                            font-size: 12px;
                        }

                        &:nth-child(1) {
                            font-size: 16px;
                            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            background-color: #ffffff;
                            left: 0;
                            position: sticky;
                            z-index: 9999;
                            background: #ffffff 0% 0% no-repeat padding-box;
                            //box-shadow: 1px 1px 1px #00000015;

                            @media screen and (max-width: 575px) {
                                font-size: 14px;
                            }

                            &::before {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: " ";
                                height: 100%;
                                top: 0;
                                left: -15px;
                                position: absolute;
                                width: 15px;
                            }

                            &::after {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: "";
                                height: 100%;
                                position: absolute;
                                top: 0;
                                right: -15px;
                                width: 15px;
                            }
                        }

                        .md-form {
                            margin-top: 10px;
                            margin-bottom: 10px;
                            margin-left: 4px;
                        }

                        input {
                            border-bottom: 1.5px solid $primary-color;
                            color: $primary-color;
                            font-size: 14px;
                            transition: none;
                            padding: 0px;
                            text-align: right;

                            &:focus,
                            &:active {
                                border: none;
                                border-bottom: 1.5px solid $primary-color;
                                box-shadow: none;
                            }

                            &:disabled {
                                border-bottom: 1.5px solid #cccccc;
                            }
                        }
                    }
                }

                .tb-total-header-row {
                    margin-top: 20px;

                    td {
                        text-align: left;
                        font-size: 14px;
                        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                        width: fit-content;
                        min-width: 60px;
                        padding: 8px 0px;

                        &:nth-child(1) {
                            text-align: center;
                            width: 250px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            background-color: #ffffff;
                            left: 0;
                            position: sticky;
                            z-index: 9999;

                            @media screen and (max-width: 575px) {
                                width: 210px;
                                min-width: 150px;
                            }

                            //box-shadow: 1px 1px 1px #00000015;

                            &::before {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: " ";
                                height: 100%;
                                top: 0;
                                left: -15px;
                                position: absolute;
                                width: 15px;
                            }

                            &::after {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: "";
                                height: 100%;
                                position: absolute;
                                top: 0;
                                right: -15px;
                                width: 15px;
                            }
                        }
                    }
                }

                .tb-total-data-row {
                    border-bottom: 1px solid #cccccc;
                    border-top: 1px solid #cccccc;

                    td {
                        text-align: left;
                        font-size: 14px;
                        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                        width: fit-content;
                        min-width: 60px;
                        padding: 6px 0px;
                        margin: auto;
                        color: $primary-color;
                        padding: 8px 0px;

                        &:nth-child(1) {
                            text-align: center;
                            width: 250px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            background-color: #ffffff;
                            left: 0;
                            position: sticky;
                            z-index: 9999;

                            @media screen and (max-width: 575px) {
                                width: 210px;
                                min-width: 150px;
                            }

                            //box-shadow: 1px 1px 1px #00000015;

                            &::before {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: " ";
                                height: 100%;
                                top: 0;
                                left: -15px;
                                position: absolute;
                                width: 15px;
                            }

                            &::after {
                                //box-shadow: 10px 0px 15px -15px inset;
                                content: "";
                                height: 100%;
                                position: absolute;
                                top: 0;
                                right: -15px;
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }

        .bp-reach-budget-recommendation-expanded-disclaimer {
            padding: 1rem 0;
            margin-bottom: 1rem;
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            border-bottom: 1px solid #cccccc;

            .bp-reach-budget-recommendation-expanded-disclaimer-title {
                font-size: 14px;
                margin-bottom: 4px;
            }

            .bp-reach-budget-recommendation-expanded-disclaimer-description {
                font-size: 12px;
            }
        }

        .bp-reach-budget-recommendation-expanded-title {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 32px;
            margin-bottom: 4px;
        }

        .bp-reach-budget-recommendation-expanded-description {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 18px;
            width: 100%;

            @media screen and (max-width: 672px) {
                width: 100%;
            }
        }
    }

    .complete-planner {
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media print {
            page-break-after: always;
        }

        .complete-planner-title {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
            font-size: 32px;
            margin-bottom: 2rem;
            padding-left: 1rem;

            .bold-title {
                font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
            }
        }

        .complete-planner-container {
            font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

            .complete-planner-data {
                padding: 1rem 3rem;
                display: flex;
                flex-direction: column;
            }

            .complete-planner-summary {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 1rem;

                .show-summary-title {
                    font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                    font-size: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 570px;
                    text-align: center;

                    @media screen and (max-width:575px) {
                        width: 100%;
                        font-size: 22px;
                    }
                }

                .complete-planner-cta {
                    font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                    font-size: 18px;
                    width: 110px;
                    height: 45px;
                    color: #fff;
                    background-color: $primary-color;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 22px;
                    margin-top: 1rem;
                }
            }
        }

        .complete-planner-table-wrap {
            display: block;
            width: 100%;

            .cp-table {
                width: 100%;
            }

            .cp-t-header {
                display: block;
                background-color: $primary-color;
                color: #fff;
                border-radius: 10px 10px 0 0;

                .c1 {
                    width: 15%;
                }

                .c2 {
                    width: 5%;
                }

                .c3 {
                    width: 6%;
                    word-break: break-all;
                }

                .cc {
                    width: 17%;
                }

                .c-total {
                    width: 6%;

                    .row-2 {
                        text-transform: uppercase;
                        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                        font-size: 14px;
                    }
                }

                .one-row-header {
                    display: inline-block;
                    font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                    font-size: 12px;
                    text-transform: uppercase;
                    text-align: center;
                    padding: 0.5rem 1rem;
                }

                .two-row-header {
                    display: inline-block;
                    padding: 0.5rem 0.5rem;

                    .row-1 {
                        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        text-transform: uppercase;
                        text-align: center;
                    }

                    .row-2 {
                        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        text-align: center;

                        div {
                            display: inline-block;
                            width: 33%;
                        }

                        .row-rt {
                            padding-right: 0.4rem;
                        }
                    }
                }
            }

            .cp-t-body {
                .cp-t-section-header {
                    display: block;
                    padding: 1rem 1rem;

                    .t-section-title {
                        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                        font-size: 16px;
                        display: inline-block;
                    }

                    .t-section-actions {
                        font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        display: inline-block;
                        float: right;
                        margin-right: 10%;

                        .t-edit-label {
                            display: inline-block;
                            margin-right: 0.5rem;
                            text-decoration: underline;
                        }

                        .t-edit-btn {
                            display: inline-block;
                            font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                            font-size: 12px;
                            color: #fff;
                            width: 50px;
                            height: 20px;
                            text-align: center;
                            background: $primary-color 0% 0% no-repeat padding-box;
                            border-radius: 22px;
                            padding-right: 0.25rem 0.5rem;
                            cursor: pointer;
                        }
                    }
                }

                .cp-t-row {
                    display: block;
                    padding: 1rem 0;
                    position: relative;
                    margin-left: 2.25rem;
                    margin-top: 1rem;
                    font-size: 12px;
                    page-break-inside: avoid;
                    break-inside: avoid;

                    .selected-icon {
                        position: absolute;
                        left: -2rem;
                    }

                    .t-r-technology {
                        display: inline-block;
                        width: 15%;
                        text-align: left;
                        padding-left: 0.5rem;

                        i {
                            color: $primary-color;
                            padding-right: 0.25rem;
                            padding-left: 0.25rem;
                        }
                    }

                    .t-r-pay {
                        display: inline-block;
                        width: 5%;
                        text-align: left;
                    }

                    .t-r-subscription {
                        display: inline-block;
                        width: 6%;
                        text-align: left;
                    }

                    .t-r-cell {
                        display: inline-block;
                        width: 5.66%;
                        text-align: left;
                    }

                    .t-r-total {
                        display: inline-block;
                        width: 6%;
                        text-align: center;
                        color: $primary-color;
                    }
                }

                .row-selected {
                    background: #e8f8ff 0% 0% no-repeat padding-box;
                    mix-blend-mode: multiply;
                    border-radius: 22px;
                }
            }

            .cp-t-footer {
                display: block;
                margin-top: 1rem;

                .footer-row-1 {
                    display: block;
                    background-color: $primary-color;
                    color: #fff;
                    height: 45px;

                    .footer-total-label {
                        display: inline-block;
                        width: 26%;
                        text-align: center;
                        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                        font-size: 16px;
                        text-transform: uppercase;
                        height: 45px;
                    }

                    .footer-total-val {
                        display: inline-block;
                        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        width: 5.66%;
                        height: 45px;
                    }

                    .footer-grand-total-val {
                        display: inline-block;
                        font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        width: 6%;
                        text-align: center;
                        height: 45px;
                    }
                }

                .footer-row-2 {
                    display: block;
                    background: #e2e2e2 0% 0% no-repeat padding-box;
                    border-radius: 0px 0px 10px 10px;
                    height: 50px;

                    .footer-empty-cell {
                        display: inline-block;
                        width: 26%;
                    }

                    .footer-quarter-label {
                        display: inline-block;
                        width: 17%;
                        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        text-transform: uppercase;
                        text-align: center;
                    }

                    .footer-annual-label {
                        display: inline-block;
                        width: 6%;
                        font-family: "HP Simplified Bold", Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.report-disclaimer {
    line-height: 20px;
}
