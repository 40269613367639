@import "../../../scss/colors";

.bp-complete-planner-start-container {
  font-family: "HP Simplified Lite", Arial, Helvetica, sans-serif;

  .bp-cps-data-container {
    height: 90vh;
    display: flex;

    .bp-cps-data {
      width: 470px;
      margin: auto;

      @media screen and (max-width: 575px) {
        width: 100%;
        padding: 25px;
      }

      .cps-data-ttl {
        font-size: 32px;

        @media screen and (max-width: 575px) {
          font-size: 22px;
        }
      }

      .cps-data-desc {
        font-size: 18px;

        @media screen and (max-width: 575px) {
          font-size: 16px;
        }
      }

      .cps-data-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        p {
          font-size: 32px;
          margin-bottom: 0;
          margin-right: 10px;
          text-transform: capitalize;
          color: $primary-color;

          @media screen and (max-width: 575px) {
            font-size: 30px;
          }
        }

        img {
          border: 1px solid $primary-color;
          border-radius: 50%;
          padding: 10px;
        }
      }
    }
  }
}

.rglr-txt {
  font-family: "HP Simplified Regular", Arial, Helvetica, sans-serif;
}
